import React, { FC, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import axios from "axios";
import { backendURL } from "../constants/environmental";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";

const ToolResponsesPage: FC<any> = () => {
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id) {
      console.log("Not logged in");
      navigate("/login");
    }
    if (user?.loggedin === "true") {
      navigate("/page-search");
    }
  });

  const userSearchTermInputRef = useRef<any>();

  const [userSearchResults, setUserSearchResults] = useState<any[]>();
  const [usersToolsWithResponses, setUsersToolsWithResponses] =
    useState<any[]>();

  const [userId, setUserId] = useState<string>();

  const [toolResponses, setToolResponses] = useState<any>();

  const [fetchingUsers, setFetchingUsers] = useState<boolean>();
  const [fetchingUsersToolsWithResponses, setFetchingUsersToolsWithResponses] =
    useState<boolean>();

  const [fetchingToolResponses, setFetchingToolResponses] = useState<boolean>();

  const [errorMessage, setErrorMessage] = useState<string>();

  return (
    <div>
      <div className="container lg:pt-2 space-y-2 lg:space-y-4">
        <h2 className="flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Tool responses
        </h2>

        <div className="flex items-center gap-2">
          <label>
            User email/name:{" "}
            <input
              className="border border-2 border-black p-2"
              ref={userSearchTermInputRef}
            />
          </label>
          <button
            className="border border-2 border-black p-2"
            onClick={() => fetchUsers(userSearchTermInputRef.current.value)}
          >
            Find
          </button>
        </div>

        {fetchingUsers ? (
          <p>Loading users...</p>
        ) : (
          userSearchResults &&
          (userSearchResults.length === 0 ? (
            <p>This user has no saved responses</p>
          ) : (
            <ul>
              {userSearchResults.map((user) => (
                <div className="flex items-center gap-8" key={user._id}>
                  <JsonView src={user} collapsed={false} />
                  <button
                    onClick={() => selectUser(user._id)}
                    className="border border-2 border-black p-2"
                  >
                    Select user
                  </button>
                </div>
              ))}
            </ul>
          ))
        )}

        {fetchingUsersToolsWithResponses ? (
          <p>Loading users tools...</p>
        ) : (
          usersToolsWithResponses &&
          (usersToolsWithResponses.length === 0 ? (
            <p>This user has no saved tool responses</p>
          ) : (
            <ul>
              {usersToolsWithResponses.map((tool) => (
                <div className="flex items-center gap-8" key={tool._id}>
                  <JsonView src={tool} collapsed={false} />
                  <button
                    onClick={() => selectTool(tool._id)}
                    className="border border-2 border-black p-2"
                  >
                    Select tool
                  </button>
                </div>
              ))}
            </ul>
          ))
        )}

        {fetchingToolResponses ? (
          <p>Loading tool responses...</p>
        ) : (
          toolResponses && <JsonView src={toolResponses} />
        )}

        {errorMessage && <p>{errorMessage}</p>}
      </div>
    </div>
  );

  function selectTool(toolId: string) {
    fetchToolResponses(userId, toolId);
  }

  function fetchToolResponses(userId: any, toolId: string) {
    setFetchingToolResponses(true);
    clearResponses();

    axios
      .get(
        `${backendURL}/admin/toolResponses?userId=${userId}&toolId=${toolId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then((response) => {
        setToolResponses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tool responses", error);
        setErrorMessage(
          "Error fetching tool responses, see backend logs for details"
        );
      })
      .finally(() => setFetchingToolResponses(false));
  }

  function selectUser(id: string) {
    setUserId(id);
    fetchUsersToolsWithResponses(id);
  }

  function fetchUsersToolsWithResponses(userId: string) {
    setFetchingUsersToolsWithResponses(true);
    clearResponses();

    axios
      .get(`${backendURL}/admin/user/${userId}/toolsWithResponses`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        setUsersToolsWithResponses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users tools", error);
        setErrorMessage(
          "Error fetching users tools, see backend logs for details"
        );
      })
      .finally(() => setFetchingUsersToolsWithResponses(false));
  }

  function fetchUsers(searchTerm: string) {
    setFetchingUsers(true);
    clearResponses();

    axios
      .get(`${backendURL}/admin/users?searchTerm=${searchTerm}`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        setUserSearchResults(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users", error);
        setErrorMessage("Error fetching users, see backend logs for details");
      })
      .finally(() => setFetchingUsers(false));
  }

  function clearResponses() {
    setUsersToolsWithResponses(undefined);
    setToolResponses(undefined);
    setUserSearchResults(undefined);
    setErrorMessage(undefined);
  }
};

export default ToolResponsesPage;
