import React, { FC, useContext } from 'react';

import FuserMainOptions from './FuserMainOptions';
import BlocksDisplay from './BlocksDisplay';
import NoBlocksDisplay from './NoBlocksDisplay';
import FuserContext from '../../context/FuserContext';

const FuserMain: FC<{}> = () => {
  const { blocks, runnerMode } = useContext(FuserContext);
  return (
    <div
      className='flex-grow flex-col pb-4 px-2 w-full gap-2'
      id='blocks-container'
    >
      <FuserMainOptions />

      <div className='blocks-height px-4 h-full overflow-y-scroll bg-neutral-100 dark:bg-neutral-800 shadow-3xl rounded-xl mt-4 lg:pb-96'>
        {blocks?.length === 0 ? <NoBlocksDisplay /> : <BlocksDisplay />}
        {!runnerMode && (
          <>
            <p>
              <b>
                Would you like us to make a custom tool for you,
                <br />
                to help automate your business - contact{' '}
                <a
                  href='mailto:mike@skillfusion.ai'
                  style={{ textDecoration: 'underline' }}
                >
                  mike@skillfusion.ai
                </a>
                .
                <br />
                We can build tools quickly and affordably with this no code
                builder.
              </b>
              <br />
              <br />
              {
                'Test your tool before publishing by using the "running man" button'
              }
              <br />
              To publish your tool on the marketplace:
              <br />- It must have 2 or more prompts to be published as a free
              tool, so it is breaking the task down
              <br />- It must have 4 or more prompts to be published as a paid
              tool
              <br />- Try to include examples within at least one of the prompts
              <br />- Check out the{' '}
              <a
                href='/maker-help'
                target='_blank'
                style={{ color: 'darkblue', textDecoration: 'underline' }}
              >
                help
              </a>{' '}
              page for more info, the video below also has useful information
            </p>
            <br />
            <div className='flex justify-start'>
              <iframe
                className='max-w-560'
                width='560'
                height='315'
                src='https://www.youtube.com/embed/21zYepA4PvE?si=HjCv2coUYW-UkNC0'
                title='YouTube video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                allowFullScreen
              ></iframe>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FuserMain;
