import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const WhatsNewPage: FC<any> = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>{"What's New || Skillfusion.ai"}</title>
        <link
          rel='canonical'
          href='https://skillfusion.ai/tools-help'
        />
      </Helmet>

      <h2 className='flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center mb-12'>
        {"What's New"}
      </h2>
      <div className='px-32 grid grid-cols-[auto_auto] justify-center items-start gap-4'>
        <p>11/09/2024</p>
        <ul className='list-disc ml-8'>
          <li>
            {
              'We have upgraded the performance of the website; in particular, loading tools and performing searches are now much faster.'
            }
          </li>
        </ul>
        <p>02/09/2024</p>
        <ul className='list-disc ml-8'>
          <li>
            {'Tool creators can now generate HD and "natural" style images.'}
          </li>
          <li>
            {
              'Any issues with tools can be reported to us directly using the "Report Issue" button on the sidebar of the tool runner. This will send diagnostic data that helps us fix issues quickly.'
            }
          </li>
        </ul>
        <p>20/08/2024</p>
        <ul className='list-disc ml-8'>
          <li>
            {
              'Tools which previously used GPT-4o will now use GPT-4o-mini, which performs similarly and is between 25-33x cheaper!'
            }
          </li>
          <li>
            We have a new spreadsheet input interface for Question Blocks; users
            can paste in and edit spreadsheet data before sending it to the next
            block. Ranges can be referenced in further blocks using Excel
            syntax.
          </li>
          <li>
            You can now specify schemas for Prompt blocks using any GPT-4o model
            which give a predictable output format (there are instructions in
            the tool maker).
          </li>
          <li>
            We have added the option to offer .docx file downloads which
            recognize HTML input.
          </li>
          <li>
            {`
              Finally, you can share your tools (published or not) with other people (Skillfusion account holders or not) and even give them editor access!
              Just enter their details in the tool maker sidebar. They will not have to pay any credits for access and they will all be able to run the tool.`}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatsNewPage;
