import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { nftsImgs } from '../../constants/fakeData';
import { Helmet } from 'react-helmet';
import FuserSidebar from '../FuserPage/FuserSidebar';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import Avatar from '../../shared/Avatar/Avatar';
import LikeButton2 from '../../components/LikeButton2';
import CommentsDisplay from '../../components/CommentsDisplay';
import { blockComponents } from '../../constants/blocks';
import { TbLayoutSidebarRightExpand } from 'react-icons/tb';
import { iconStyle, menuButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import { prettyPrintMultidimensionalStringArray } from '../../utils/array';
import { truncateAfter } from '../../utils/string';

const SharedBlock: FC<any> = () => {
  const authHeader = useAuthHeader()();

  const location = useLocation();
  const blockId = location.pathname
    .split('/')
    // filter empty parts in case of trailing slash
    .filter(pathPart => pathPart !== '')
    .at(-1);

  const user = useAuthUser();
  const userId = user()?.id;

  const [blockJSX, setBlockJSX] = useState<any>();
  const [toolData, setToolData] = useState<any>();
  const [sharerData, setSharerData] = useState<any>();
  const [shareMetadata, setShareMetadata] = useState<any>();
  const [usersWhoLikedBlock, setUsersWhoLikedBlock] = useState<any>();
  const [statusMessage, setStatusMessage] = useState<string>('');

  const currentUserHasLikedBlock = usersWhoLikedBlock?.some(
    ({ _id }: any) => _id === userId
  );

  const load = async () => {
    try {
      setStatusMessage('');
      const [getSharedBlockResponse, getUsersWhoLikedBlockResponse]: any =
        await Promise.allSettled([
          axios.get(`${backendURL}/blocks/shared/${blockId}`),
          axios.get(`${backendURL}/blocks/shared/${blockId}/likes`),
        ]);

      if (getSharedBlockResponse.status === 'rejected')
        throw new Error(getSharedBlockResponse.reason);

      const sharedBlockData = getSharedBlockResponse.value.data.sharedBlockData;
      const block = {
        id: '',
        type: sharedBlockData.blockType,
        data: {
          response: sharedBlockData.response,
          inputToProcess: sharedBlockData.inputToProcess,
          blockHeading: sharedBlockData.blockHeading,
        },
      };
      setSharerData({
        id: sharedBlockData.sharer._id,
        name: sharedBlockData.sharer.name,
        profileImage: sharedBlockData.sharer.profileImage,
      });
      setToolData({
        id: sharedBlockData.tool._id,
        title: sharedBlockData.tool.name,
        description: sharedBlockData.tool.description,
        isPublished: sharedBlockData.tool.isPublished,
        authorCoverPhoto: sharedBlockData.tool.authorId.profileImage,
        authorId: sharedBlockData.tool.authorId._id,
        authorName: sharedBlockData.tool.authorId.name,
        inputToProcess: sharedBlockData.inputToProcess,
        response: sharedBlockData.response,
        imageURLs: sharedBlockData.imageURLs,
        type: sharedBlockData.blockType,
      });
      setShareMetadata({
        viewCount: sharedBlockData.viewCount,
        uploadError: sharedBlockData.uploadError,
        customTitle: sharedBlockData.customTitle,
        linkToProfile: sharedBlockData.linkToProfile,
      });
      const BlockComponent =
        blockComponents[`blockdiv-${sharedBlockData.blockType}`].component;
      const { response, imageURLs } = sharedBlockData;
      // console.log(sharedBlockData);
      setBlockJSX(
        <BlockComponent
          block={block}
          index={0}
          // if the images have not finished uploading to cloudinary use the links provided by openAI in response
          imageURLs={response}
          sharedImageURLs={imageURLs}
          setImageURLs={() => {}}
          toolId={sharedBlockData.tool._id}
          stillRunning={false}
          updateBlockData={() => {}}
          isLoading={false}
          handleChange={() => {}}
          setIsLoading={() => {}}
          resultHtml={(response
            ? truncateAfter(
                1000,
                Array.isArray(response)
                  ? prettyPrintMultidimensionalStringArray(response)
                  : response
              )
            : ''
          ).replace(
            /<(\/?)(script|style)>/g,
            (match, closingSlash, tagName) =>
              `&lt;${closingSlash}${tagName}&gt;`
          )}
          tool_id={sharedBlockData.tool._id}
          collapsed={false}
          isShared={true}
          sharedBy={sharedBlockData.sharer.name}
        />
      );
      // console.log(getUsersWhoLikedBlockResponse);
      if (getUsersWhoLikedBlockResponse.status === 'rejected') {
        // console.log(getUsersWhoLikedBlockResponse.reason);
        setStatusMessage('Error fetching likes');
      } else {
        const usersWhoLiked =
          getUsersWhoLikedBlockResponse.value.data.usersWhoLiked;
        setUsersWhoLikedBlock(
          usersWhoLiked.some(({ _id }: any) => _id === userId)
            ? [
                // put current user at the top
                { _id: userId, name: 'You' },
                ...usersWhoLiked.filter(({ _id }: any) => _id !== userId),
              ]
            : usersWhoLiked
        );
      }
    } catch (error) {
      console.log('Error loading blocks', error);
      setStatusMessage('Error loading shared block');
    }
  };

  useEffect(() => {
    load();
  }, []);

  const toggleLike = async () => {
    try {
      const toggleResult = await axios.post(
        `${backendURL}/blocks/shared/${blockId}/like`,
        { liked: !currentUserHasLikedBlock },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      // console.log(toggleResult);
      setUsersWhoLikedBlock((users: any) =>
        toggleResult.data.liked
          ? [
              { _id: userId, name: 'You' },
              // filter in case the user spam clicks and two consecutive responses with liked:true arrive
              ...users.filter(({ name }: any) => name !== 'You'),
            ]
          : users.filter(({ _id }: any) => _id !== userId)
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        return setStatusMessage('Please log in to like this block');
      }
      console.log('error toggling like:', error);
    }
  };

  // const blockClasses = `border-2 my-4 p-4 flex flex-col rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800 grow`;

  const navigate = useNavigate();

  const goToToolDetailsPage = () => {
    return navigate(`/fusion/${toolData?.id}`);
  };

  let id, otherFuserSidebarProps;
  if (toolData) ({ id, ...otherFuserSidebarProps } = toolData);

  const setSharedBlockVisibilityOnProfile = (visible: boolean) => async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/blocks/shared/${blockId}`,
        { linkToProfile: visible },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        setShareMetadata((previous: any) => ({
          ...previous,
          linkToProfile: visible,
        }));
        // console.log(shareMetadata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [newCustomTitle, setNewCustomTitle] = useState<string>('');
  const [titleBeingEdited, setTitleBeingEdited] = useState<boolean>(false);
  const [deleteConfirmationShowing, setDeleteConfirmationShowing] =
    useState<boolean>(false);

  const editSharedBlockTitle = (title: string) => async () => {
    try {
      const response = await axios.patch(
        `${backendURL}/blocks/shared/${blockId}`,
        { customTitle: title },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        setTitleBeingEdited(false);
        setShareMetadata((previous: any) => ({
          ...previous,
          customTitle: title,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setNewCustomTitle('');
    }
  };

  const deleteSharedBlock = async () => {
    try {
      const response = await axios.delete(
        `${backendURL}/blocks/shared/${blockId}`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      if (response?.status === 200) {
        return navigate(`/profile/${userId}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className='p-2 bg-white dark:bg-neutral-900 lg:mb-12 flex flex-col sm:flex-row'
      data-nc-id='BuilderPage'
    >
      <Helmet>
        <title>Shared Block || Skillfusion.ai</title>
        <link
          rel='canonical'
          href={`https://skillfusion.ai/sharedBlock/${blockId}`}
        />
      </Helmet>
      <div
        className='grow flex flex-col pb-4 px-2 w-full gap-2 overflow-x-scroll overflow-y-scroll'
        id='blocks-container'
      >
        <div className='w-full dark:bg-neutral-800 mt-2 p-2 py-4 flex items-center justify-between rounded-lg shadow-xl text-lg flex gap-2'>
          {userId === sharerData?.id && toolData?.type === 'image'
            ? !toolData?.imageURLs
              ? 'Upload in progress, this may take a few minutes. Please refresh the page to check the current status.'
              : toolData?.imageURLs?.length !== toolData?.response?.length ||
                  shareMetadata.uploadError
                ? "Sorry, some images failed to upload, the images will only display for about 20 minutes from the time when they were generated before they will be taken down from OpenAI's servers"
                : `Image${
                    toolData?.imageURLs?.length !== 1 ? 's' : ''
                  } successfully uploaded!`
            : ''}
          {!isSidebarOpen && (
            <button
              className={menuButtonStyles + ' hidden sm:block'}
              onClick={toggleSidebar}
            >
              <TbLayoutSidebarRightExpand className={iconStyle} />
            </button>
          )}
        </div>
        <div className='border-2 my-4 p-4 flex flex-col rounded-xl gap-2 text-black shadow-xl bg-gradient-to-b from-blue-100 to-purple-200 transition-opacity duration-1000 ease-in-out opacity-0 animate-fade-in dark:bg-neutral-800'>
          {blockJSX}
        </div>
        {userId && userId === sharerData?.id && (
          <div className='flex gap-2 items-center'>
            {shareMetadata?.customTitle && (
              <>
                {titleBeingEdited ? (
                  <>
                    <input
                      className='border-2 border-black rounded-lg px-2'
                      value={newCustomTitle}
                      onChange={({ target: { value } }) =>
                        setNewCustomTitle(value)
                      }
                    />
                    <button
                      className='border border-black rounded-lg px-2'
                      onClick={editSharedBlockTitle(newCustomTitle)}
                    >
                      Save new title
                    </button>
                    <button
                      className='border border-black rounded-lg px-2'
                      onClick={() => {
                        setNewCustomTitle('');
                        setTitleBeingEdited(false);
                      }}
                    >
                      Discard changes
                    </button>
                  </>
                ) : (
                  <>
                    <h2 className='font-bold text-lg'>
                      {shareMetadata.customTitle}
                    </h2>
                    <button
                      className='border border-black rounded-lg px-2'
                      onClick={() => {
                        setNewCustomTitle(shareMetadata?.customTitle);
                        setTitleBeingEdited(true);
                      }}
                    >
                      Edit title
                    </button>
                  </>
                )}
              </>
            )}
            <label>Visible on profile</label>
            <input
              type='checkbox'
              checked={shareMetadata?.linkToProfile}
              onChange={setSharedBlockVisibilityOnProfile(
                !shareMetadata?.linkToProfile
              )}
            />
            {deleteConfirmationShowing ? (
              <>
                <span>Confirm unshare:</span>
                <button
                  className='border border-black rounded-lg px-2'
                  onClick={deleteSharedBlock}
                >
                  Confirm
                </button>
                <button
                  className='border border-black rounded-lg px-2'
                  onClick={() => setDeleteConfirmationShowing(false)}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className='border border-black rounded-lg px-2'
                onClick={() => setDeleteConfirmationShowing(true)}
              >
                Unshare
              </button>
            )}
          </div>
        )}
        <div className='flex flex-wrap justify-around items-center gap-x-16 gap-y-8'>
          {sharerData?.name && (
            <div className='flex items-center '>
              <Avatar
                imageUrl={`${sharerData?.profileImage || nftsImgs[3]}`}
                sizeClass='h-9 w-9'
                radius='rounded-full'
              />
              <span className='ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col'>
                <span className='text-sm'>Shared by</span>
                <span className='text-neutral-900 dark:text-neutral-200 font-medium flex items-center'>
                  <span>
                    <Link to={`/profile/${sharerData?.id}`}>
                      <p>{sharerData?.name} </p>
                      <p className='underline'>Visit Profile</p>
                    </Link>
                  </span>
                </span>
              </span>
            </div>
          )}
          {toolData &&
            (toolData?.isPublished ? (
              <p className='text-gray-500 text-center'>
                Generated by:{' '}
                <a
                  className='hover:cursor-pointer text-black underline'
                  onClick={goToToolDetailsPage}
                >
                  {toolData.title}
                </a>
              </p>
            ) : (
              <p>{/*This tool has not been published yet*/}</p>
            ))}
          {shareMetadata && <p>Views: {shareMetadata.viewCount ?? 0}</p>}
          {toolData && (
            <LikeButton2
              handleLikeClick={toggleLike}
              liked={currentUserHasLikedBlock ?? false}
              usersWhoLiked={usersWhoLikedBlock ?? []}
              id={`like-${blockId}`}
            />
          )}
          {statusMessage && <p>{statusMessage}</p>}
        </div>

        <CommentsDisplay
          targetType='shared-block'
          targetId={blockId}
          isOwner={false}
          noReplies={true}
        />
      </div>
      {isSidebarOpen && (
        <FuserSidebar
          isForSharedBlock={true}
          toolId={toolData?.id}
          toggleSidebar={toggleSidebar}
          {...otherFuserSidebarProps}
        />
      )}
    </div>
  );
};

export default SharedBlock;
