import { createContext } from 'react';
import Block from '../models/Block';

interface FuserContextProps {
  restartRunnerMode: any;
  processAtSigns: any;
  subscriptionInfo: any;
  blocks: any;
  collapsedBlocks: any;
  runnerIndex: any;
  runnerMode: any;
  themes: any;
  selectedTheme: any;
  isFullScreen: any;
  isJsonVisible: any;
  hasAccess: any;
  stillRunning: any;
  selectedTextareaId: any;
  selectedBlockId: any;
  selectedBlockReference: any;
  isLoading: any;
  title: any;
  description: any;
  isPublished: any;
  isRequestPublished: any;
  activityLog: any;
  isOwner: any;
  isAuthor: any;
  coverPhoto: any;
  price: any;
  priceFrequency: any;
  userId: any;
  authorId: any;
  authorName: any;
  authorCoverPhoto: any;
  textAreaRefs: any;
  blockRefs: any;
  responseVisibility: any;
  imageURLsForBlockWithId: any;
  setCoverPhoto: any;
  resultHtml: any;
  waitingForClearUnsavedResponses: any;
  addBlock: any;
  toggleBlockCollapse: any;
  errorLoadingBlocks: any;
  deleteBlock: any;
  moveBlockDown: any;
  moveBlockUp: any;
  duplicateBlock: any;
  changeBlockType: any;
  toggleRunnerMode: any;
  handleThemeChange: any;
  undoChanges: any;
  redoChanges: any;
  toggleSidebar: any;
  toggleFullScreen: any;
  toggleJsonVisibility: any;
  clearUnsavedResponses: any;
  pauseRunnerMode: any;
  resumeRunnerMode: any;
  setStillRunning: any;
  saveBlock: any;
  setSelectedBlockReference: any;
  setIsLoading: any;
  handleTitleChange: any;
  handleDescriptionChange: any;
  insertReference: any;
  handleToggle: any;
  setFocusedTextArea: any;
  setIsPublished: any;
  setIsRequestPublished: any;
  setPrice: any;
  setPriceFrequency: any;
  setBlocks: any;
  setActivityLog: any;
  setImageURLsForBlockWithId: any;
  replacePlaceholders: (promptText: string, blocks: Block[]) => any;
  evaluatePlaceholder: any;
  toggleResponseVisibility: any;
  handleChange: any;
  handleTextareaFocus: any;
  handleTextareaBlur: any;
  onDownloadResponseClick: any;
  updateBlockData: any;
  setWaitingForClearUnsavedResponses: any;
  onRunnerModeClick: any;
  toolId: any;
  blocksHaveLoaded: any;
  saveBlockStatusMessage: any;
  setSaveBlockStatusMessage: any;
  tidyUpResponse: any;
  setRunnerIndex: any;
  setRunnerModeBlocksData: any;
  categories: any;
  setCategories: any;
  chosenCategories: any;
  setChosenCategories: any;
  autosaveMode: any;
  setAutosaveMode: any;
  blockCancellationState: any;
  setBlockCancellationState: any;
  cancellingBlocksMemo: any;
  setCollapsedBlocks: any;
  setSubscriptionInfo: any;
  blockDataKeysHoldingReferences: any;
  tags: any;
  setTags: any;
  blockToShare: any;
  setBlockToShare: any;
  shareModalOpen: any;
  setShareModalOpen: any;
  shareMessage: any;
  setShareMessage: any;
  furtherInfo: any;
  handleFurtherInfoChange: any;
  isSidebarOpen: any;
  saveRunnerModeBlockData: any;
  runnerIndexHistory: any;
  setMinimalMode: any;
  minimalMode: any;
  blockStyles: any;
  restartQueued: any;
  anyBlocksLoading: any;
  setBlockScrollingIntoView: any;
  blockScrollingIntoView: any;
  setShowReportIssueDialog: any;
  runnerModeBlocksData: any;
}

const FuserContext = createContext<FuserContextProps>({
  runnerModeBlocksData: null,
  setShowReportIssueDialog: null,
  blockScrollingIntoView: null,
  setBlockScrollingIntoView: null,
  anyBlocksLoading: null,
  restartQueued: null,
  restartRunnerMode: null,
  blockStyles: null,
  minimalMode: null,
  setMinimalMode: null,
  runnerIndexHistory: null,
  saveRunnerModeBlockData: null,
  isSidebarOpen: null,
  furtherInfo: null,
  handleFurtherInfoChange: null,
  shareMessage: null,
  setShareMessage: null,
  shareModalOpen: null,
  setShareModalOpen: null,
  setBlockToShare: null,
  blockToShare: null,
  tags: null,
  setTags: null,
  processAtSigns: null,
  subscriptionInfo: null,
  cancellingBlocksMemo: null,
  setSubscriptionInfo: null,
  blockCancellationState: null,
  setBlockCancellationState: null,
  autosaveMode: null,
  setAutosaveMode: null,
  chosenCategories: null,
  setChosenCategories: null,
  categories: null,
  setCategories: null,
  setRunnerModeBlocksData: null,
  setRunnerIndex: null,
  saveBlockStatusMessage: null,
  blocksHaveLoaded: null,
  toolId: null,
  blocks: null,
  collapsedBlocks: null,
  runnerIndex: null,
  runnerMode: null,
  themes: null,
  selectedTheme: null,
  isFullScreen: null,
  isJsonVisible: null,
  hasAccess: null,
  stillRunning: null,
  selectedTextareaId: null,
  selectedBlockId: null,
  selectedBlockReference: null,
  isLoading: null,
  title: null,
  description: null,
  isPublished: null,
  isRequestPublished: null,
  activityLog: null,
  isOwner: null,
  isAuthor: null,
  coverPhoto: null,
  price: null,
  priceFrequency: null,
  userId: null,
  authorId: null,
  authorName: null,
  authorCoverPhoto: null,
  textAreaRefs: null,
  blockRefs: null,
  responseVisibility: null,
  imageURLsForBlockWithId: null,
  resultHtml: null,
  waitingForClearUnsavedResponses: null,
  errorLoadingBlocks: null,
  blockDataKeysHoldingReferences: null,
  setCoverPhoto: () => {},
  setSaveBlockStatusMessage: () => {},
  setCollapsedBlocks: () => {},
  addBlock: () => {},
  toggleBlockCollapse: () => {},
  deleteBlock: () => {},
  moveBlockDown: () => {},
  moveBlockUp: () => {},
  duplicateBlock: () => {},
  changeBlockType: () => {},
  toggleRunnerMode: () => {},
  handleThemeChange: () => {},
  undoChanges: () => {},
  redoChanges: () => {},
  toggleSidebar: () => {},
  toggleFullScreen: () => {},
  toggleJsonVisibility: () => {},
  clearUnsavedResponses: () => {},
  pauseRunnerMode: () => {},
  resumeRunnerMode: () => {},
  setStillRunning: () => {},
  saveBlock: () => {},
  setSelectedBlockReference: () => {},
  setIsLoading: () => {},
  handleTitleChange: () => {},
  handleDescriptionChange: () => {},
  insertReference: () => {},
  handleToggle: () => {},
  setFocusedTextArea: () => {},
  setIsPublished: () => {},
  setIsRequestPublished: () => {},
  setPrice: () => {},
  setPriceFrequency: () => {},
  setBlocks: () => {},
  setActivityLog: () => {},
  setImageURLsForBlockWithId: () => {},
  replacePlaceholders: () => {},
  evaluatePlaceholder: () => {},
  toggleResponseVisibility: () => {},
  handleChange: () => {},
  handleTextareaFocus: () => {},
  handleTextareaBlur: () => {},
  onDownloadResponseClick: () => {},
  updateBlockData: () => {},
  setWaitingForClearUnsavedResponses: () => {},
  onRunnerModeClick: () => {},
  tidyUpResponse: () => {},
});

export default FuserContext;
