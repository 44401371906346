import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import axios from 'axios';
import { backendURL } from '../constants/environmental';
import { updateAtIndex } from '../utils/array';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

const BugReportsPage: FC<any> = () => {
  const authHeader = useAuthHeader()();
  const user = useAuthUser()();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id) {
      console.log('Not logged in');
      navigate('/login');
    }
    if (user?.loggedin === 'true') {
      navigate('/page-search');
    }
  });

  useEffect(fetchBugReportSummaries, []);

  const [bugReports, setBugReports] = useState<any>();

  const [isFullyExpanded, setIsFullyExpanded] = useState<boolean[]>();

  const [fetchingBugReportSummaries, setFetchingBugReportSummaries] =
    useState<boolean>(true);

  const [fetchingLogsAndRunnerModeData, setFetchingLogsAndRunnerModeData] =
    useState<boolean[]>();

  return (
    <div>
      <div className='container lg:pt-2 space-y-2 lg:space-y-4'>
        <h2 className='flex items-center text-xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center'>
          Bug reports
        </h2>

        {fetchingBugReportSummaries ? (
          <p>Loading...</p>
        ) : bugReports === undefined ? (
          <p>Error loading bug reports</p>
        ) : bugReports.length === 0 ? (
          <p>There are no bug reports in the database</p>
        ) : (
          <ul className='flex flex-col gap-8'>
            {bugReports.map((bugReport: any, index: number) => (
              <li key={index}>
                <p>{`Date: ${new Date(bugReport.date).toLocaleString()}`}</p>
                <p>{`Tool: ${bugReport.tool.name} (${bugReport.tool._id})`}</p>
                <p>{`Info: ${bugReport.info}`}</p>
                {fetchingLogsAndRunnerModeData![index] ? (
                  <p>Loading...</p>
                ) : !(
                    bugReport.consoleLogs ||
                    bugReport.errorLogs ||
                    bugReport.runnerModeData
                  ) ? (
                  <button
                    className='underline text-blue-600'
                    onClick={() => fetchLogsAndRunnerModeData(bugReport._id)}
                  >
                    Fetch user logs & runner mode data
                  </button>
                ) : (
                  <div className='flex flex-col gap-2'>
                    <label htmlFor=''>
                      <span className='flex items-center gap-1'>
                        Logs and runner mode data: (
                        <input
                          type='checkbox'
                          onChange={({ target: { checked } }) =>
                            setIsFullyExpanded((previous: any) =>
                              updateAtIndex(index, checked, previous)
                            )
                          }
                        />
                        Expand fully?)
                      </span>
                      <JsonView
                        src={{
                          'Error logs': bugReport.errorLogs,
                          'Console logs': bugReport.consoleLogs,
                          'Runner mode data': bugReport.runnerModeData,
                        }}
                        collapsed={!isFullyExpanded![index]}
                      />
                    </label>
                  </div>
                )}
                <p>{`User: ${bugReport.user.name} (${bugReport.user.username}, ${bugReport.user._id})`}</p>
                <p>{`Browser: ${bugReport.browser}`}</p>
                <p>{`Operating System: ${bugReport.operatingSystem}`}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );

  function fetchBugReportSummaries() {
    setFetchingBugReportSummaries(true);
    axios
      .get(`${backendURL}/admin/bug-reports/summaries`, {
        headers: {
          Authorization: authHeader,
        },
      })
      .then(response => {
        setBugReports(response.data);
        setFetchingLogsAndRunnerModeData(
          Array.from({ length: response.data.length }, () => false)
        );
        setIsFullyExpanded(
          Array.from({ length: response.data.length }, () => false)
        );
      })
      .catch(error => console.error('Error fetching bug reports', error))
      .finally(() => setFetchingBugReportSummaries(false));
  }

  function fetchLogsAndRunnerModeData(bugReportId: string) {
    const bugReport = bugReports.find(({ _id }: any) => _id === bugReportId);
    const bugReportIndex = bugReports.findIndex(
      ({ _id }: any) => _id === bugReportId
    );

    setFetchingLogsAndRunnerModeData((previous: any) =>
      updateAtIndex(bugReportIndex, true, previous)
    );

    axios
      .get(
        `${backendURL}/admin/bug-reports/${bugReportId}/logsAndRunnerModeData`,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      )
      .then(response => {
        setBugReports((previous: any) => {
          Object.assign(bugReport, response.data);
          return updateAtIndex(bugReportIndex, bugReport, previous);
        });

        setFetchingLogsAndRunnerModeData((previous: any) =>
          updateAtIndex(bugReportIndex, false, previous)
        );
      })
      .catch(error => console.error('Error fetching bug reports', error))
      .finally(() => setFetchingBugReportSummaries(false));
  }
};

export default BugReportsPage;
