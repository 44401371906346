import ReactGA from 'react-ga4';
import React, {
  BrowserRouter,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';
import { Page } from './types';
import Footer from '../shared/Footer/Footer';
import PageHomeLoggedOut from '../containers/PageHome/PageHomeLoggedOut';
import PageHomeLoggedIn from '../containers/PageHome/PageHomeLoggedIn';
import Page404 from '../containers/Page404/Page404';
import AuthorPage from '../containers/AuthorPage/AuthorPage';
import Dashboard from '../containers/PageDashboard';
import AccountPage from '../containers/AccountPage/AccountPage';
import PageContact from '../containers/PageContact/PageContact';
import PageAbout from '../containers/PageAbout/PageAbout';
import PageOffers from '../containers/PageOffers/PageOffers';
import ZapierHelpC from '../containers/PageHelp/ZapierHelpC';
import EmbeddingsHelpC from '../containers/PageHelp/EmbeddingsHelpC';
import ZapierHelp from '../containers/PageHelp/ZapierHelp';
import CreditHelp from '../containers/PageHelp/CreditsHelp';
import OpportunityHelp from '../containers/PageHelp/OpportunityHelp';
import MakerHelp from '../containers/PageHelp/MakerHelp';
import ChatHelp from '../containers/PageHelp/ChatHelp';
import QualityHelp from '../containers/PageHelp/QualityHelp';
import PageSignUp from '../containers/PageSignUp/PageSignUp';
import PageLogin from '../containers/PageLogin/PageLogin';
// import PageSubcription from "../containers/PageSubcription/PageSubcription";
import SiteHeader from '../containers/SiteHeader';
import PageCollection from '../containers/PageCollection';
import PageSearch from '../containers/PageSearch';
// import PageUploadFusion from '../containers/UploadGig/PageUploadFusion';
import GigDetailsPage from '../containers/PageGig/PageGigDetails';
//import CreatorForm from "../containers/AuthorPage/CreatorForm";
import ChatPage from '../containers/ChatPage';
import Fuser from '../containers/FuserPage/Fuser';
import SharedBlock from '../containers/AuthorPage/SharedBlock';
import FusionDetailPage from '../containers/PageGig/PageGigDetails';
// import MyBlocks from "../containers/AuthorPage/MyBlocks";
// import Messages from "../containers/AuthorPage/Messages";
import ForgotPassword from '../components/ForgotPassword/ForgotPassword';
import ResetPassword from '../components/ForgotPassword/ResetPassword';
import VerifyEmail from '../containers/PageLogin/VerifyEmail';
import PagePay from '../containers/PagePay/PagePay';
import { useAuthUser } from 'react-auth-kit';
import { useState, useEffect } from 'react';
import AdminPage from '../containers/AdminPage';
import SalesPage from '../containers/SalesPage';
import CategoriesPage from '../containers/CategoriesPage';
import { ErrorBoundary } from 'react-error-boundary';
import ReferAFriendModal from '../components/ReferAFriendModal';
import HeaderContext from '../context/HeaderContext';
import PageSEO from '../containers/PageHome/SeoPage';
import ProcessingBlockHelp from '../containers/PageHelp/ProcessingBlockHelp';
import ToolsHelp from '../containers/PageHelp/ToolsHelp';
import SharedBlocksPage from '../containers/SharedBlocksPage.tsx/SharedBlocksPage';
import TrackingPage from '../containers/TrackingPage';
import BugReportsPage from '../containers/BugReportsPage';
import ToolResponsesPage from '../containers/ToolResponsesPage';
import StripeTestPage from '../containers/StripeTestPage';
import { useLocation } from 'react-router-dom';
import WhatsNewPage from '../containers/WhatsNewPage';

const ScrollToTop = () => {
  const locationPathname = useLocation().pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [locationPathname]);
  return null;
};

const FuserWrapper = () => {
  const { blocksId } = useParams();
  return <Fuser key={blocksId} />;
};

const MyRoutes = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const auth = useAuthUser();

  useEffect(() => {
    if (auth()?.id) {
      setLoggedIn(true);
    } else if (!auth()?.id) {
      setLoggedIn(false);
    }
  }, [auth()?.id]);

  const homeComponent = loggedIn ? PageHomeLoggedIn : PageHomeLoggedOut;

  const pages: Page[] = [
    { path: '/', component: homeComponent },

    { path: '/home-header-2', component: PageHomeLoggedOut },

    { path: '/page-collection/:skillId', component: PageCollection },

    { path: '/page-search', component: PageSearch },

    { path: '/lp-seo', component: PageSEO },

    { path: '/profile/:uid', component: AuthorPage },
    { path: '/edit_account/:uid', component: AccountPage },

    { path: '/dashboard/:uid', component: Dashboard },

    // { path: "/creator-form", component: CreatorForm },

    { path: '/forgot-password', component: ForgotPassword },
    { path: '/reset-password/:uid', component: ResetPassword },

    { path: '/messages/:uid', component: ChatPage },

    // { path: "/publish_fusion/:blocksId", component: PageUploadFusion },

    { path: '/gig/:gigId', component: GigDetailsPage },
    { path: '/stripeTest', component: StripeTestPage },

    { path: '/admin-page/:uid', component: AdminPage },
    { path: '/sales-page/:uid', component: SalesPage },
    { path: '/tracking-page', component: TrackingPage },
    { path: '/bug-reports', component: BugReportsPage },
    { path: '/tool-responses', component: ToolResponsesPage },
    { path: '/categories-page', component: CategoriesPage },

    { path: '/contact', component: PageContact },
    { path: '/whats-new', component: WhatsNewPage },
    { path: '/about', component: PageAbout },
    { path: '/signup', component: PageSignUp },
    { path: '/login', component: PageLogin },
    // { path: '/subscription/:uid', component: PageSubcription },

    { path: '/sharedBlock/:blockId', component: SharedBlock },

    { path: '/fuser/:blocksId', component: FuserWrapper },
    { path: '/fusion/:fusionId', component: FusionDetailPage },

    // { path: '/myblocks/:uid', component: MyBlocks },
    // { path: '/mymessages/:uid', component: Messages },
    { path: '/verify-email/:tempid', component: VerifyEmail },
    { path: '/buy-credits/:uid', component: PagePay },

    { path: '/contact', component: PageContact },
    { path: '/about', component: PageAbout },
    { path: '/offers/:skillId', component: PageOffers },
    { path: '/signup', component: PageSignUp },
    { path: '/sharedBlocks', component: SharedBlocksPage },
    { path: '/login', component: PageLogin },
    // { path: "/subscription/:uid", component: PageSubcription },

    { path: '/fuser/:blocksId', component: FuserWrapper },
    { path: '/fusion/:fusionId', component: FusionDetailPage },

    // { path: "/myblocks/:uid", component: MyBlocks },
    // { path: "/mymessages/:uid", component: Messages },
    { path: '/verify-email/:tempid', component: VerifyEmail },
    { path: '/buy-credits/:uid', component: PagePay },
    { path: '/buy-credits/', component: PagePay },

    // help pages
    { path: '/zapier-help-c', component: ZapierHelpC },
    { path: '/embeddings-help-c', component: EmbeddingsHelpC },
    //{ path: "/question-help-c", component: ZapierHelpC },
    //{ path: "/prompt-help-c", component: ZapierHelpC },
    { path: '/zapier-help', component: ZapierHelp },
    { path: '/processing-block-help', component: ProcessingBlockHelp },
    { path: '/tools-help', component: ToolsHelp },
    { path: '/credit-help', component: CreditHelp },
    { path: '/opportunity-help', component: OpportunityHelp },
    { path: '/maker-help', component: MakerHelp },
    { path: '/chat-help', component: ChatHelp },
    { path: '/quality-help', component: QualityHelp },
  ];

  const HomePageFallback = ({ error }: any) => (
    <div className='gap w-full h-48 flex items-center justify-center'>
      Sorry, this page has crashed
      {error?.message ? ` with the following error: ${error.message}` : ''}
    </div>
  );

  const onHomePageError = (error: Error) => {
    ReactGA.event('homepage_crashed', {
      errorMessage: error?.message,
      stack: error?.stack,
    });
  };

  const [menuBarVisible, setMenuBarVisible] = useState(false);

  const [referAFriendModalOpen, setReferAFriendModalOpen] = useState(false);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContext.Provider
        value={{ setReferAFriendModalOpen, menuBarVisible, setMenuBarVisible }}
      >
        <SiteHeader />
        {/* <ReferAFriendModal
          isOpen={referAFriendModalOpen}
          onClose={() => setReferAFriendModalOpen(false)}
        /> */}
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            if (path === '/')
              return (
                <Route
                  key={path}
                  element={
                    <ErrorBoundary
                      FallbackComponent={HomePageFallback}
                      onError={onHomePageError}
                    >
                      <Component />{' '}
                    </ErrorBoundary>
                  }
                  path={path}
                />
              );
            return (
              <Route
                key={path}
                element={<Component />}
                path={path}
              />
            );
          })}
          <Route element={<Page404 />} />
        </Routes>
        <Footer />
      </HeaderContext.Provider>
    </BrowserRouter>
  );
};

export default MyRoutes;
