import React, { FC, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import Block from '../../models/Block';
import FuserContext from '../../context/FuserContext';
import useBlockRunner from '../../hooks/useBlockRunner';
import { useCredit } from '../../context/CreditContext';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { useAuthHeader } from 'react-auth-kit';
import {
  geolocationCodePairs,
  languageToGeolocationCodePair,
} from '../../constants/location';
import { testButtonStyles } from '../../constants/styles';
import { backendURL } from '../../constants/environmental';
import { deepMap, unflatten, updateAtIndex } from '../../utils/array';
import { truncateAfter } from '../../utils/string';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';

const GoogleSearchBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  block,
  handleChange,
  toolId,
  collapsed,
  // resultHtml,
}) => {
  const {
    blockStyles,
    replacePlaceholders,
    runnerMode,
    blocks,
    setBlocks,
    // handleTextareaFocus,
    // textAreaRefs,
  } = useContext(FuserContext);

  const { credit, updateCredits } = useCredit();
  const authHeader = useAuthHeader()();

  useEffect(() => {
    if (block.data.numberOfResults === undefined) {
      block.data.numberOfResults = 10;
    }
    if (block.data.multipleChoiceAnswers === undefined) {
      block.data.multipleChoiceAnswers = [];
    }
    if (block.data.searchLocationCode === undefined) {
      block.data.searchLocationCode =
        languageToGeolocationCodePair?.[navigator?.language] ?? 'us';
    }
    updateBlocks();
  }, []);

  const onTestClick = async () => {
    setErrorMessage('');
    setIsLoading(true);

    const {
      inputToProcess,
      numberOfResults,
      recencyOfResults = 30,
      searchLocationCode,
    } = block.data;

    let processedInput: any = inputToProcess
      ? replacePlaceholders(inputToProcess, blocks)
      : '';

    if (!Array.isArray(processedInput)) {
      processedInput = [processedInput];
    }

    console.log('processedInput', processedInput);

    // recencyOfResults
    let afterString = '';
    if (multipleChoiceAnswers?.includes('recentDays')) {
      // where does multipleChoiceAnswers update
      //console.log('adding afterString');
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - recencyOfResults);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // months are 0-indexed in JS
      const day = String(currentDate.getDate()).padStart(2, '0');
      afterString = ` after:${year}-${month}-${day}`;
      //console.log('afterString', afterString);
    }

    processedInput = deepMap(
      (PI: string) => PI.replace(/['"]/g, '') + afterString,
      processedInput
    ); // remove quotes as it breaks the search

    console.log('search input: ' + processedInput);

    const flatInputs = processedInput.flat(Infinity);

    try {
      const finalResults: string[] = [];

      for (let i = 0; i < flatInputs.length; i++) {
        // console.log('single search input: ' + flatInputs[i]);
        if (flatInputs?.[i] === '') {
          throw new Error('Error: No search term entered');
        }

        const response = await axios.post(
          `${backendURL}/fusion/googleSearch`,
          {
            searchExpression: flatInputs[i],
            numberOfResults,
            searchLocationCode,
            toolId,
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );

        //console.log('results:', response.data.results);
        if (response.data.updatedCredit != null)
          updateCredits(response.data.updatedCredit * 10);

        const results = response.data.results
          .map((result: any) => {
            const responseParts: string[] = [];
            for (const key in fieldDisplayNames) {
              const displayName = fieldDisplayNames[key];
              const field = result[key];
              if (multipleChoiceAnswers.includes(key) && field) {
                responseParts.push(
                  key === 'cacheId'
                    ? `Link to cached page: https://webcache.googleusercontent.com/search?q=cache:${result.cacheId}:${result.link}`
                    : `${displayName}: ${field}`
                );
              }
            }
            return responseParts.join('\n');
          })
          .join('\n---\n');

        // console.log('results:', results);

        finalResults.push(results);

        // end of loop
      }

      console.log(unflatten(finalResults, processedInput));
      setBlocks((blocks: Block[]) => {
        const newBlocks = [...blocks];

        newBlocks[index] = {
          ...newBlocks[index],
          updatedBlock: true,
          data: {
            ...newBlocks[index].data,
            type: 'googleSearch',
            response: unflatten(finalResults, processedInput),
          },
        };
        return newBlocks;
      });
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.error ??
          error?.message ??
          'There was an error performing this search'
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useBlockRunner(onTestClick, index);

  const [errorMessage, setErrorMessage] = useState<string>('');

  // const onTextareaFocus =
  //   (textareaId: number) =>
  //   (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
  //     handleTextareaFocus(e, block.id, textareaId);
  //   };

  useEffect(() => setErrorMessage(''), [runnerMode]);

  const onNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.validity.valid) {
      handleChange(e);
    }
  };

  const updateBlocks = () => setBlocks(updateAtIndex(index, block, blocks));

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    const { multipleChoiceAnswers } = block.data;

    if (checked) {
      block.data.multipleChoiceAnswers = [...multipleChoiceAnswers, value];
    } else {
      block.data.multipleChoiceAnswers = multipleChoiceAnswers.filter(
        (answer: string) => answer !== value
      );
    }
    updateBlocks();
  };

  const fieldDisplayNames: Record<string, string> = {
    link: 'Link',
    snippet: 'Snippet',
    title: 'Title',
    displayLink: 'Display link',
    formattedUrl: 'Formatted URL',
    htmlFormattedUrl: 'HTML Formatted URL',
    htmlSnippet: 'HTML Snippet',
    htmlTitle: 'HTML Title',
    cacheId: 'Cache link',
    recentDays: 'Limit to recent results',
  };

  const {
    response,
    inputToProcess,
    numberOfResults,
    multipleChoiceAnswers,
    recencyOfResults = 30,
    searchLocationCode,
  } = block.data;

  const resultDisplay = (
    <textarea
      value={response}
      className='resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll w-full'
      readOnly
    />
  );

  if (collapsed) {
    if (response?.length > 0)
      return (
        <>
          <div>Google search result</div>
          <p>{truncateAfter(MAX_PREVIEW_CHARS, response.toString())} </p>
        </>
      );
    else return <div>Google Search block</div>;
  }

  return (
    <FuserLoader
      name='Google Search Block'
      loading={isLoading}
    >
      {runnerMode ? (
        response && response?.length > 0 ? (
          <>
            <p>Results:</p>
            {resultDisplay}
          </>
        ) : (
          <>
            <div>Google Search block</div>
            <p>{errorMessage}</p>
          </>
        )
      ) : (
        <div className={blockStyles}>
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Search terms:
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            textAreaIndex={0}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          <label htmlFor='numberOfResults'>
            Number of results (max 100):{' '}
            <input
              className='w-20'
              type='number'
              min='1'
              max='100'
              name='numberOfResults'
              pattern='^[0-9]{0,3}$'
              value={numberOfResults ?? 10}
              onChange={onNumberInputChange}
            />
          </label>

          <p>Fields to return (if they exist):</p>
          {Object.keys(fieldDisplayNames).map((key: string) => {
            const displayName = fieldDisplayNames[key];
            return (
              <div
                key={key}
                className='bg-transparent flex gap-1 items-center'
              >
                <input
                  type='checkbox'
                  id={`multiple-choice-answer-${key}`}
                  name='response'
                  value={key}
                  checked={multipleChoiceAnswers?.includes(key)}
                  onChange={handleCheckboxChange}
                />

                <label htmlFor='response'>{displayName}</label>
                {key === 'recentDays' ? (
                  <>
                    :{' '}
                    <input
                      className='w-20 h-6'
                      type='number'
                      min='0'
                      name='recencyOfResults'
                      pattern='^[0-9]{0,3}$'
                      value={recencyOfResults ?? 30}
                      onChange={onNumberInputChange}
                    />{' '}
                    days ago{' '}
                  </>
                ) : null}
              </div>
            );
          })}

          <label htmlFor='searchLocationCode'>
            Search location:{' '}
            <select
              name='searchLocationCode'
              value={searchLocationCode}
              onChange={handleChange}
            >
              {geolocationCodePairs.map(([country, code]) => (
                <option
                  key={code}
                  value={code}
                >
                  {country}
                </option>
              ))}
            </select>
          </label>

          <button
            onClick={onTestClick}
            className={testButtonStyles}
          >
            Search
          </button>

          {errorMessage === '' || <p>{errorMessage}</p>}

          {resultDisplay}
        </div>
      )}
    </FuserLoader>
  );
};

export default GoogleSearchBlock;
