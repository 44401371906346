import { NavItemType } from '../shared/Navigation/NavigationItem';
// import SwitchDarkMode from '../shared/SwitchDarkMode/SwitchDarkMode';
// import { useContext } from 'react';
// import HeaderContext from '../context/HeaderContext';
// import { nftsImgs } from '../constants/fakeData';

function ncNanoId(prefix = "nc_") {
  return (
    prefix + Date.now() + "_" + Math.floor(Math.random() * 999999999999999)
  );
}

export const helpPages = (): NavItemType[] => [
  { id: ncNanoId(), href: '/tools-help', name: 'How to use our tools' },
  {
    id: ncNanoId(),
    href: '/about',
    name: 'About Skillfusion',
  },
  {
    id: ncNanoId(),
    href: '/credit-help',
    name: 'About Credits',
  },
  //  {    id: ncNanoId(),     href: "/contact",     name: "Contact us",  },
  //  {     id: ncNanoId(),     href: "https://blog.skillfusion.ai/",     name: "Blog",   },
  {
    id: ncNanoId(),
    href: '/zapier-help',
    name: "User's Guide To Blocks",
    type: 'dropdown',
    children: [
      {
        id: ncNanoId(),
        href: '/zapier-help',
        name: 'Zapier Block',
      },
      // {
      //   id: ncNanoId(),
      //   href: '/question-help',
      //   name: 'Question Blocks',
      // },
      // {
      //   id: ncNanoId(),
      //   href: 'prompt-help',
      //   name: 'Prompt Blocks',
      // },
    ],
  },
  {
    id: ncNanoId(),
    href: '/maker-help',
    name: "Creator's Guide To Blocks",
    type: 'dropdown',
    children: [
      {
        id: ncNanoId(),
        href: '/maker-help',
        name: 'Tool Maker Help',
      },
      {
        id: ncNanoId(),
        href: '/chat-help',
        name: 'Chatbot Help',
      },
      {
        id: ncNanoId(),
        href: '/quality-help',
        name: 'Advice On Quality',
      },
      {
        id: ncNanoId(),
        href: '/zapier-help-c',
        name: 'Zapier Block',
      },
      {
        id: ncNanoId(),
        href: '/question-help-c',
        name: 'Question Blocks',
      },
      {
        id: ncNanoId(),
        href: '/embeddings-help-c',
        name: 'Embeddings Blocks',
      },
      {
        id: ncNanoId(),
        href: '/prompt-help-c',
        name: 'Prompt Blocks',
      },
      {
        id: ncNanoId(),
        href: '/processing-block-help',
        name: 'Processing Blocks',
      },
    ],
  },
];

export const NAVIGATION_DEMO_2 = ({
  uid,
  credit,
  profileImage,
  sidebar,
  // onReferAFriendClick,
}: any): any[] => {
  const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;

  if (uid === undefined) {
    return [
      {
        id: ncNanoId(),
        href: '/page-search',
        name: 'All Tools',
      },
      {
        id: ncNanoId(),
        href: '/tools-help',
        name: 'Help center',
        type: 'dropdown',
        children: helpPages(),
      },
      {
        id: ncNanoId(),
        href: '/login',
        name: 'Login',
      },
      {
        id: ncNanoId(),
        href: '/signup',
        name: 'Register',
      },
    ];
  }

  const allToolsSection = {
    id: ncNanoId(),
    href: '/page-search',
    name: 'All Tools',
    className: 'hidden md:block',
    type: 'dropdown',
    children: [
      {
        id: ncNanoId(),
        href: '/page-search',
        name: 'Browse All Tools',
      },
      {
        id: ncNanoId(),
        href: `/dashboard/${uid}?type=user`,
        name: 'My Purchased Tools',
      },
      {
        id: ncNanoId(),
        href: '/fuser/0',
        name: 'Tool Maker',
        className: 'hidden lg:block',
      },
      {
        id: ncNanoId(),
        href: `/dashboard/${uid}?type=creator`,
        name: 'My Creations',
      }, 
      {
        id: ncNanoId(),
        href: `/whats-new`,
        name: "What's new",
      }, 
      // {
      //   id: ncNanoId(),
      //   href: '/sharedBlocks',
      //   name: 'My Shared Blocks',
      // }, 
    ],
  };

  const helpCenterSection = {
    id: ncNanoId(),
    href: '/tools-help',
    name: 'Help center',
    type: 'dropdown',
    children: helpPages(),
    className: 'hidden lg:block',
  };

  const creditsSection: any = {
    id: ncNanoId(),
    href: `/buy-credits`,
    name: `Credits: ${credit !== undefined ? parseFloat(credit).toFixed(1) : 'N/A'
      }`
  };

  if (!sidebar && !isTouchScreen) {
    creditsSection.type = 'dropdown';
    creditsSection.children = [
      {
        id: ncNanoId(),
        href: `/buy-credits/${uid}`,
        name: 'Buy Credits',
      },
      {
        id: ncNanoId(),
        href: '/credit-help',
        name: 'About Credits',
      },
    ];
  }

  const buyCreditsSection:any = {
    id: ncNanoId(),
    href: `/buy-credits/${uid}`,
    name: 'Buy Credits',
    className: 'hidden md:block',
  };

  if (sidebar) {
    buyCreditsSection.type = 'dropdown';
    buyCreditsSection.children = [
      {
        id: ncNanoId(),
        href: `/buy-credits/${uid}`,
        name: 'Buy Credits',
      },
      {
        id: ncNanoId(),
        href: '/credit-help',
        name: 'About Credits',
      },
    ];
  }

  const toolMakerSection = {
    id: ncNanoId(),
    href: `/fuser/0`,
    name: 'Make A Tool',
    className: 'hidden md:block',
    type: 'dropdown',
    children: [
      {
        id: ncNanoId(),
        href: '/fuser/0',
        name: 'Tool Maker',
      },
      {
        id: ncNanoId(),
        href: '/maker-help',
        name: 'Tool Maker Help',
      },
      {
        id: ncNanoId(),
        href: '/chat-help',
        name: 'Chatbot Help',
      }
    ],
  };

  const profileSection = {
    id: ncNanoId(),
    href: `/profile/${uid}`,
    image: profileImage,
    type: 'imageDropdown',
    className: 'hidden lg:block',
    children: [
      {
        id: ncNanoId(),
        href: `/profile/${uid}`,
        name: 'My Profile',
      },
      {
        id: ncNanoId(),
        href: `/edit_account/${uid}`,
        name: 'Account settings',
      },
      {
        id: ncNanoId(),
        href: '#logout',
        name: 'Logout',
      },
    ],
  };

  const menuData = [];

  menuData.push(allToolsSection);

  if (sidebar || !isTouchScreen) {
    menuData.push(helpCenterSection)
  }

  menuData.push(creditsSection, buyCreditsSection, toolMakerSection);
 
  if (sidebar || !isTouchScreen) {
    menuData.push(profileSection)
  }

  return menuData;
};
