import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/index.scss';
import './index.css';
import './fonts/line-awesome-1.3.0/css/line-awesome.css';
import 'rc-slider/assets/index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';
import {
  storeErrorInLocalStorage,
  storeLogInLocalStorage,
} from './utils/logging';

// store logs and errors in localstorage in case users want to report them
try {
  const l = localStorage; // throws an error if it's not available

  const originalConsoleLogFunction = console.log;
  console.log = function (...data) {
    // run the original console.log function with the same context and args
    originalConsoleLogFunction.apply(console, data);
    storeLogInLocalStorage(...data);
  };

  // Override console.error to store errors
  const originalConsoleErrorFunction = console.error;
  console.error = function (message, ...optionalParams) {
    // run the original console.error function with the same context and args
    originalConsoleErrorFunction.apply(console, [message, ...optionalParams]);
    storeErrorInLocalStorage(message, ...optionalParams);
  };

  // Store unhandled errors
  window.onerror = function (message, source, lineno, colno, error) {
    storeErrorInLocalStorage(message, { source, lineno, colno, error });
    return false; // This means the error is still shown in the console
  };

  // Store unhandled promise rejections
  window.onunhandledrejection = function (event) {
    storeErrorInLocalStorage(
      // copy the reason object since the properties are not enumerable and
      // will be lost when converted to a string
      { reason: event.reason.message, stack: event.reason.stack },
      {
        type: 'Unhandled Promise Rejection',
      }
    );
  };
} catch (error) {
  // localstorage not available, carry on as normal
  console.log(error);
}

// reddit
RedditPixel.init('a2_dx62bkev0fjf');
RedditPixel.pageVisit();

// Initialize GA4 with your Measurement ID
ReactGA.initialize('G-6TP0SCH9Y7');

// Track the initial pageview
ReactGA.event('page_view', {
  page_path: window.location.pathname + window.location.search,
});

const options = {
  autoConfig: true,
  debug: false,
};
ReactPixel.init('1273471480198682', undefined, options);
ReactPixel.track('PageView', { path_name: window.location.pathname });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      {/* <p style={{backgroundColor:"lightblue",textAlign:"center"}}>Currently down for maintenance for the next hour</p>*/}
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
