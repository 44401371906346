import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import BlockProps from '../../models/BlockProps';
import MyToolTips from '../../components/MyTooltip';
import FuserContext from '../../context/FuserContext';
import Block from '../../models/Block';
import useBlockRunner from '../../hooks/useBlockRunner';
import TextareaAutosize from 'react-textarea-autosize';
import ReactTextareaAutosize from 'react-textarea-autosize';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { testButtonStyles } from '../../constants/styles';
import {
  ensureNotArray,
  multiDimensionalArray,
  joinEverything,
  updateAtIndex,
  updateAtIndexRun,
  is1dArray,
  filterInner,
} from '../../utils/array';
import { escapeRegExpPattern } from '../../utils/string';

const ProcessingBlock: FC<BlockProps> = ({
  isLoading,
  setIsLoading,
  index,
  resultHtml,
  block,
  handleChange,
}) => {
  const {
    blockStyles,
    handleTextareaFocus,
    replacePlaceholders,
    runnerMode,
    blocks,
    setBlocks,
    setActivityLog,
    textAreaRefs,
    tidyUpResponse,
    processAtSigns,
  } = useContext(FuserContext);

  const updateBlocks = () => setBlocks(updateAtIndex(index, block, blocks));

  useEffect(() => {
    const defaultValues: Record<string, string> = {
      inputToProcess: '',
      selectValue: 'numbered-list',
      checkedRadioValue: 'process',
      referenceModifier: 'increment',
      appendSecondOperand: '',
      customSeparator: '',
      customMatchPattern: '',
      customMatchFlags: '',
      newValue: '',
      changeAmountString: '1',
      cropAmountString: '1000',
      numberOfWordsToRemoveFromStart: '400',
      removeFirstMatchPattern: '',
      removeFirstMatchFlags: '',
    };
    for (const dataKey in defaultValues) {
      if (block.data[dataKey] === undefined) {
        block.data[dataKey] = defaultValues[dataKey];
      }
      if (block.data.removeEmptyElements === undefined) {
        block.data.removeEmptyElements = false;
      }
    }
    updateBlocks();
  }, []);

  const [responseDescription, setResponseDescription] = useState('');
  const removeNumberPrefix = (str: string) =>
    str?.replace(/^\d+[\s\S.)]\s+/, '');

  const onProcessingSaveClick = async () => {
    setIsLoading(true);
    setStatusMessage('');

    let blockUpdater;

    if (checkedRadioValue === 'modify-references') {
      const {
        inputToProcess,
        referenceModifier,
        appendSecondOperand,
        changeAmountString,
        cropAmountString,
        numberOfWordsToRemoveFromStart,
      } = block.data;
      const extractChangeAmountRegex = /-?\d+/;
      // console.log(changeAmountString);
      let changeAmount = changeAmountString?.match(
        extractChangeAmountRegex
      )?.[0];
      if (changeAmount !== undefined) {
        changeAmount = +changeAmount;
      } else {
        changeAmount = 1;
      }

      const blockNumberRegex = /<(\d+):output>/g;

      // console.log(inputToProcess);

      const blockIndicesToModifyIterator =
        processAtSigns(inputToProcess)?.matchAll(blockNumberRegex);

      const blockIndicesToModify =
        blockIndicesToModifyIterator === undefined
          ? []
          : [...blockIndicesToModifyIterator].map(
              ([match, blockNumberString]: any) => +blockNumberString
            );

      let returnedValue: string | undefined;

      blockUpdater = (blocks: any) => {
        const indexOfChatBlock = +(
          block.data.chatBlockNumber ??
          // default to the first chat block
          blocks.findIndex(
            ({ type }: { type: string }) => type === 'blockdiv-chat'
          )
        );

        const newBlocks = blocks.map((block: any, blockIndex: number) => {
          if (referenceModifier.startsWith('send-to-chat-block')) {
            if (index === blockIndex) {
              block.updatedBlock = true;
            }

            if (blockIndex !== indexOfChatBlock) {
              return block;
            }

            const extraInfo = replacePlaceholders(
              inputToProcess,
              blocks
            ).toString();

            const {
              temporaryExtraInfo,
              response: userMessage,
              nextMessageEndIndex,
              inputToProcess: chatHistory,
            } = block.data;

            if (referenceModifier === 'send-to-chat-block-temporary') {
              block.data.temporaryExtraInfo =
                (temporaryExtraInfo ?? '') + extraInfo;
            }

            if (referenceModifier.startsWith('send-to-chat-block-permanent')) {
              if (
                !chatHistory ||
                chatHistory.length - 1 === nextMessageEndIndex
              ) {
                block.data.inputToProcess += 'AI: ';
              }
              block.data.inputToProcess += extraInfo;
              if (referenceModifier.endsWith('dont-process')) {
                block.data.dontSendToOpenAI = true;
              }
            }

            return block;
          } else if (blockIndicesToModify?.includes(blockIndex)) {
            const { response } = block.data;
            // this assumes that the response to append to is a string or an array with at most 1 string
            const responseModifier = (response: string) => {
              console.log(response, referenceModifier);
              if (
                ['increment', 'decrement', 'multiply-by'].includes(
                  referenceModifier
                )
              ) {
                const found = response.match(/\d+/);
                if (found) {
                  const number = parseInt(found[0], 10);
                  return response.replace(
                    number.toString(),
                    (referenceModifier === 'increment'
                      ? number + changeAmount
                      : referenceModifier === 'decrement'
                        ? number - changeAmount
                        : referenceModifier === 'multiply-by'
                          ? number * changeAmount
                          : ''
                    ).toString()
                  );
                }
                return response;
              } else if (referenceModifier === 'clear') {
                return '';
              } else if (referenceModifier === 'append') {
                let outputToAppend: string | multiDimensionalArray<string> =
                  replacePlaceholders(appendSecondOperand, blocks);
                if (Array.isArray(outputToAppend)) {
                  outputToAppend = joinEverything(', ', outputToAppend);
                }
                return response + outputToAppend;
              } else if (referenceModifier === 'keep-start') {
                return response.slice(0, cropAmountString);
              } else if (referenceModifier === 'keep-end') {
                return response.slice(-cropAmountString);
              } else if (
                referenceModifier ===
                'remove-words-from-start-and-set-as-response'
              ) {
                const words = response.split(' ');

                returnedValue = words
                  .slice(0, numberOfWordsToRemoveFromStart)
                  .join(' ');

                return words.slice(numberOfWordsToRemoveFromStart).join(' ');
              } else if (
                referenceModifier === 'remove-first-match-and-set-as-response'
              ) {
                const matchRegex = new RegExp(
                  removeFirstMatchPattern,
                  removeFirstMatchFlags
                );

                console.log(matchRegex);
                const matchedText = response.match(matchRegex)?.[0];

                console.log('matched text:', matchedText);
                if (matchedText === null) {
                  returnedValue = undefined;
                  return response;
                }

                returnedValue = matchedText;
                return response.slice(matchedText?.length);
              } else {
                throw new Error(
                  `Invalid reference modifier: ${referenceModifier}`
                );
              }
            };

            const modifiedResponse = responseModifier(
              typeof response === 'string'
                ? response
                : response === undefined
                  ? ''
                  : Array.isArray(response) && response.length === 0
                    ? ''
                    : response?.[0]
            );

            const modifiedBlock = {
              ...block,
              data: {
                ...block.data,
                response: modifiedResponse,
              },
              [index === blockIndex ? 'updatedBlock' : 'saveQueued']: true,
            };

            if (referenceModifier === 'clear' && block.blocktype === 'chat') {
              modifiedBlock.data.inputToProcess = '';
            }

            return modifiedBlock;
          } else {
            return {
              ...block,
              ...(index === blockIndex ? { updatedBlock: true } : {}),
            };
          }
        });
        if (returnedValue) {
          blocks[index].data.response = returnedValue;
        }
        setResponseDescription(
          returnedValue === undefined ? 'References modified' : ''
        );
        return newBlocks;
      };
    } else if (checkedRadioValue === 'process') {
      const { inputToProcess, selectValue: processingType } = block.data;

      let processedInput: multiDimensionalArray<string> | string =
        inputToProcess ? replacePlaceholders(inputToProcess, blocks) : '';
      if (Array.isArray(processedInput) && processedInput.length === 1) {
        processedInput = processedInput[0];
      }

      //console.log('Processing input: ', processedInput);

      const makeCustomRegex = () => {
        let flags = customMatchFlags;
        if (captureGroupIndex && !flags.includes('g')) {
          // ensure the regex is global since matchAll in this case
          flags += 'g';
        }
        const processedMatchPattern = ensureNotArray(
          replacePlaceholders(customMatchPattern, blocks)
        );
        // console.log(processedMatchPattern);
        return new RegExp(processedMatchPattern, flags);
      };

      const splitDataForProcessingType: any = {
        'numbered-list': {
          //splitRegex = /(?:^|\n)\s*\d+[\.)\s]*/gm;
          // starts match at numbers followed by space/period/colon,
          // and ends match as the next one starts /
          // when a line containing just a line break is found /
          // at the end of the whole text
          splitRegex: /^(\d+[\s).:].+?)(?=\n\d+[\s).:]|(\n$)|(?!.))/gms,
          //splitRegex = /\n(\d+\.)/;
          splitType: 'match',
        },
        'line-breaks': {
          splitRegex: /\s*[\r\n]+\s*/g,
          splitType: 'split',
        },
        paragraph: {
          splitRegex: /\s*[\r\n]\s*[\r\n]\s*/g,
          splitType: 'split',
        },
        comma: {
          splitRegex: / *, */gm,
          splitType: 'split',
        },
        '400words': {
          splitRegex: /.{1,2000}$/gms,
          splitType: 'match',
        },
        '800words': {
          splitRegex: /.{1,4000}$/gms,
          splitType: 'match',
        },
        'custom-separator': {
          splitRegex: new RegExp(
            escapeRegExpPattern(
              ensureNotArray(replacePlaceholders(customSeparator, blocks))
            ),
            'g'
          ),
          splitType: 'split',
        },
        'custom-match': {
          splitRegex: makeCustomRegex(),
          splitType: 'match',
        },
        'find-remove': {
          splitRegex: makeCustomRegex(),
          splitType: 'remove',
        },
        undefined: {
          splitRegex: /^ *\d+[.) ]*/gm,
          splitType: 'split',
        },
      };

      let splitRegex: RegExp;
      let splitType: string;

      if (Object.keys(splitDataForProcessingType).includes(processingType)) {
        const splitData = splitDataForProcessingType[processingType];
        splitRegex = splitData.splitRegex;
        splitType = splitData.splitType;
      } else if (processedInput === undefined) {
        splitRegex = /^ *\d+[.) ]*/gm;
        splitType = 'split';
      } else {
        throw new Error('Invalid split type');
      }

      console.log({ processingType, splitType, splitRegex });

      const deepProcess = (
        item: multiDimensionalArray<string> | string
      ): any => {
        if (typeof item === 'string') {
          if (splitType === 'split') {
            const splitItem = item
              .split(splitRegex)
              .map((splitItem: string) => splitItem.trim())
              .filter(splitItem => splitItem !== '');
            return splitItem;
          }
          if (splitType === 'match') {
            //item = item + '\n\n';

            // console.log(splitRegex, [...item.matchAll(splitRegex)]);
            const matches = captureGroupIndex
              ? [...item.matchAll(splitRegex)].map(
                  (match: string[]) => match[captureGroupIndex]
                )
              : item.match(splitRegex);

            // console.log(item, captureGroupIndex, splitRegex, matches);
            const splitItems = matches?.map(removeNumberPrefix);
            return splitItems ?? [];
            // let splitItems: any = [];
            // let match;
            // while ((match = splitRegex.exec(item)) !== null) {
            //   console.log(match);
            //   splitItems.push(removeNumberPrefix(match[1]));
            // }
            // return splitItems ?? [];
          } else if (splitType === 'remove') {
            // Remove all matches from the item and return the rest.
            const replacedItem = item.replace(
              splitRegex,
              replacePlaceholders(newValue, blocks)
            );
            return replacedItem;
          }
        } else return item?.map(deepProcess);
      };

      let response = deepProcess(processedInput);

      if (splitType === 'remove' && block.data.removeEmptyElements) {
        response = filterInner(
          (x: any) => ![null, undefined, ''].includes(x),
          response
        );
      }

      block.data.response = response;

      console.log({ processedInput, response });
      blockUpdater = (blocks: Block[]) =>
        updateAtIndexRun(index, block, blocks);

      setResponseDescription(tidyUpResponse(response));
    }

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved processing block at index: ${index}`,
    ]);

    setStatusMessage('Processing complete');

    //console.log('Processing response: ', response);
    if (!runnerMode) setBlocks(blockUpdater);
    else return blockUpdater;
  };

  useBlockRunner(onProcessingSaveClick, index);

  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => setStatusMessage(''), [runnerMode]);

  const onTextareaFocus =
    (textareaId: number) =>
    (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
      handleTextareaFocus(e, block.id, textareaId);
    };

  const {
    inputToProcess,
    selectValue,
    referenceModifier,
    customSeparator,
    customMatchPattern,
    customMatchFlags,
    newValue,
    appendSecondOperand,
    checkedRadioValue,
    changeAmountString,
    cropAmountString,
    numberOfWordsToRemoveFromStart,
    removeFirstMatchPattern,
    removeFirstMatchFlags,
    captureGroupIndex,
    chatBlockNumber,
  } = block.data;

  const radioGroupName = `radio-group-${index}`;

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectName = e.target.name;
    if (!['selectValue', 'referenceModifier'].includes(selectName)) {
      console.log(
        'select change handler called with invalid select name:',
        selectName
      );
      return;
    }
    block.data[selectName] = e.target.value;
    block.data.checkedRadioValue =
      selectName === 'selectValue' ? 'process' : 'modify-references';

    const inputToProcess = block.data.inputToProcess ?? '';

    const inputIsEnclosedInSquareBrackets =
      inputToProcess.startsWith('[') && inputToProcess.endsWith(']');

    if (
      e.target.value === 'send-to-chat-block-temporary' &&
      !inputIsEnclosedInSquareBrackets
    ) {
      block.data.inputToProcess = `[System: ${
        block.data.inputToProcess || ''
      }]`;
    }
    updateBlocks();
  };

  const handleRadioTextareaChange =
    (selectName: string) => (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (['selectValue', 'referenceModifier'].includes(selectName)) {
        block.data.checkedRadioValue =
          selectName === 'selectValue' ? 'process' : 'modify-references';
        updateBlocks();
      } else
        console.log(
          'select change handler called with invalid select name:',
          selectName
        );

      handleChange(e);
    };

  return (
    <FuserLoader
      name='Processing Block'
      loading={isLoading}
    >
      {runnerMode ? (
        <>
          <div>Processing block</div>
          <p>{statusMessage}</p>
        </>
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          <label
            className='text-xs'
            id='prompt-textarea'
          >
            Input to be processed:{' '}
            <MyToolTips
              content='
            <p>This processing block will split up content so you can use it in separate prompts.</p> 

            <p>For instance you can ask chatGPT for a numbered ten point list for a long article. You would then use this block to split that up.</p>
            
            <p>Then any block where you use this output would run ten times, once for each item in the list.</p>
            
            <p>You can also split lists in to sub lists. E.g for a non fiction book you may do a 12 chapter plan, then a 6 point plan for each chapter. So then you would have 12 x 6 point plan you can then use in the prompt block to write the book.</p>
            
            <p>You can also use it to split content up that you may want chatGPT to summarise, translate, or rewrite.</p>
            '
              tipID='block-types'
              datatooltipplace='below'
            />
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            textAreaIndex={0}
            className='w-full prompt-textarea bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          <br />
          <br />

          <label
            id='process-select'
            className='flex gap-2 items-center'
          >
            <input
              type='radio'
              id='radio-process'
              name={radioGroupName}
              value={'process'}
              onChange={handleChange}
              checked={'process' === checkedRadioValue}
            />
            Process and output:
          </label>

          <select
            className='text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner '
            id='process-select'
            name='selectValue'
            value={selectValue}
            onChange={handleSelectChange}
          >
            <option value='numbered-list'>
              Numbered output to actual list
            </option>
            <option value='line-breaks'>Separate text by line</option>
            <option value='paragraph'>Separate text by paragraph</option>
            <option value='comma'>Comma separated to actual list</option>
            <option value='400words'>400 Words to nearest paragraph</option>
            <option value='800words'>800 Words to nearest paragraph</option>
            <option value='custom-separator'>Custom separator</option>
            <option value='custom-match'>Custom match</option>
            <option value='find-remove'>Find and replace</option>
          </select>

          {selectValue === 'custom-separator' && (
            <div className='w-full flex items-center gap-2'>
              <label
                htmlFor='customSeparator'
                className='w-max'
              >
                Enter custom separator here:
              </label>
              <AutocompleteTextarea
                autosize={true}
                block={block}
                index={index}
                onChange={handleRadioTextareaChange('selectValue')}
                textAreaIndex={1}
                containerClassName='grow flex'
                className='w-full bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                name='customSeparator'
                value={customSeparator ?? ''}
              />
            </div>
          )}

          {selectValue === 'custom-match' && (
            <div className='flex flex-col gap-2'>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchPattern'
              >
                Enter custom match pattern here: (this field is interpreted as
                regular expression syntax){' '}
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  className='w-1/3 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchPattern'
                  value={customMatchPattern ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  textAreaIndex={2}
                />
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchFlags'
              >
                Enter flags here (e.g. for global and case insensitive write
                gi):{' '}
                <ReactTextareaAutosize
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchFlags'
                  value={customMatchFlags ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  onFocus={onTextareaFocus(3)}
                  ref={el => {
                    textAreaRefs.current[`${block.id}:3`] = el;
                  }}
                />
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchFlags'
              >
                (Optional): Enter the number of the capturing group you want to
                extract
                <ReactTextareaAutosize
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='captureGroupIndex'
                  value={captureGroupIndex ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  onFocus={onTextareaFocus(4)}
                  ref={el => {
                    textAreaRefs.current[`${block.id}:4`] = el;
                  }}
                />
              </label>
            </div>
          )}

          {selectValue === 'find-remove' && (
            <div className='flex flex-col gap-2'>
              <label className='flex gap-2 items-center'>
                <input
                  type='checkbox'
                  onChange={() => {
                    block.data.removeEmptyElements =
                      !block.data.removeEmptyElements;
                    updateBlocks();
                  }}
                  checked={block.data.removeEmptyElements}
                />
                Remove empty elements (only applicable if the input is an array)
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchPattern'
              >
                Enter custom match pattern here: (this field is interpreted as
                regular expression syntax){' '}
                <ReactTextareaAutosize
                  className='w-1/3 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchPattern'
                  value={customMatchPattern ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  onFocus={onTextareaFocus(2)}
                  ref={el => {
                    textAreaRefs.current[`${block.id}:2`] = el;
                  }}
                />
              </label>
              <label
                className='flex flex-col gap-2'
                htmlFor='customMatchFlags'
              >
                Enter flags here (e.g. for global and case insensitive write
                gi):{' '}
                <ReactTextareaAutosize
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='customMatchFlags'
                  value={customMatchFlags ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  onFocus={onTextareaFocus(3)}
                  ref={el => {
                    textAreaRefs.current[`${block.id}:3`] = el;
                  }}
                />
                Enter new value here ($& inserts the match, $n inserts the nth
                capturing group):
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='newValue'
                  value={newValue ?? ''}
                  onChange={handleRadioTextareaChange('selectValue')}
                  textAreaIndex={4}
                />
              </label>
            </div>
          )}

          <label
            id='modifier-select'
            className='flex gap-2 items-center'
          >
            <input
              type='radio'
              id='radio-modify-references'
              name={radioGroupName}
              value={'modify-references'}
              onChange={handleChange}
              checked={'modify-references' === checkedRadioValue}
            />
            Modify references
          </label>

          <div className='flex gap-2 items-center'>
            <select
              className='grow text-xs bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner'
              id='modifier-select'
              name='referenceModifier'
              value={referenceModifier}
              onChange={handleSelectChange}
            >
              <option value='increment'>Increase by</option>
              <option value='decrement'>Decrease by</option>
              <option value='multiply-by'>Multiply by</option>
              <option value='append'>Append</option>
              <option value='clear'>Clear</option>
              <option value='keep-start'>Keep the first</option>
              <option value='keep-end'>Keep the last</option>
              <option value='remove-words-from-start-and-set-as-response'>
                Remove words from start and set as the response
              </option>
              <option value='remove-first-match-and-set-as-response'>
                Remove first match and set as the response
              </option>
              <option value='send-to-chat-block-temporary'>
                {"Send extra info to chat block (don't add to chat history)"}{' '}
              </option>
              <option value='send-to-chat-block-permanent'>
                Send extra info to chat block (add to chat history)
              </option>
              <option value='send-to-chat-block-permanent-dont-process'>
                {
                  "Send extra info to chat block (add to chat history and don't send to openAI)"
                }
              </option>
            </select>

            {['increment', 'decrement', 'multiply-by'].includes(
              referenceModifier
            ) && (
              <label
                className='flex gap-2 items-center'
                htmlFor='changeAmountString'
              >
                <p className='text-sm'>Enter amount: </p>
                <TextareaAutosize
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='changeAmountString'
                  value={changeAmountString ?? ''}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  onFocus={onTextareaFocus(4)}
                  ref={el => {
                    textAreaRefs.current[`${block.id}:4`] = el;
                  }}
                />
              </label>
            )}

            {['keep-start', 'keep-end'].includes(referenceModifier) && (
              <>
                <label
                  className='flex gap-2 items-center'
                  htmlFor='cropAmountString'
                ></label>
                <TextareaAutosize
                  className='bg-transparent rounded-xl text-sm border border-black-400 shadow-inner'
                  name='cropAmountString'
                  value={cropAmountString ?? ''}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  onFocus={onTextareaFocus(5)}
                  ref={el => {
                    textAreaRefs.current[`${block.id}:5`] = el;
                  }}
                />
                <p className='text-sm'>characters</p>
              </>
            )}

            {referenceModifier === 'append' && (
              <label
                className='flex gap-2 items-center'
                htmlFor='appendSecondOperand'
              >
                <p className='text-sm'>Enter second item to append: </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  textAreaIndex={6}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='appendSecondOperand'
                  value={appendSecondOperand ?? ''}
                />
              </label>
            )}

            {referenceModifier ===
              'remove-words-from-start-and-set-as-response' && (
              <label
                className='flex gap-2 items-center'
                htmlFor='numberOfWordsToRemoveFromStart'
              >
                <p className='text-sm'>Number of words to remove: </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleRadioTextareaChange('referenceModifier')}
                  textAreaIndex={7}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                  name='numberOfWordsToRemoveFromStart'
                  value={numberOfWordsToRemoveFromStart ?? ''}
                />
              </label>
            )}

            {referenceModifier === 'remove-first-match-and-set-as-response' && (
              <div className='flex flex-col gap-2'>
                <label
                  className='flex gap-2 items-center'
                  htmlFor='removeFirstMatchPattern'
                >
                  <p className='text-sm'>Match to remove: </p>
                  <AutocompleteTextarea
                    autosize={true}
                    block={block}
                    index={index}
                    onChange={handleRadioTextareaChange('referenceModifier')}
                    textAreaIndex={8}
                    containerClassName='flex items-center'
                    className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                    name='removeFirstMatchPattern'
                    value={removeFirstMatchPattern ?? ''}
                  />
                </label>
                <label
                  className='flex flex-col gap-2'
                  htmlFor='removeFirstMatchFlags'
                >
                  Enter flags here (e.g. for global and case insensitive write
                  gi):{' '}
                  <ReactTextareaAutosize
                    className='w-1/6 bg-transparent rounded-xl text-sm border border-black-100 shadow-inner'
                    name='removeFirstMatchFlags'
                    value={removeFirstMatchFlags ?? ''}
                    onChange={handleRadioTextareaChange('referenceModifier')}
                    onFocus={onTextareaFocus(9)}
                    ref={el => {
                      textAreaRefs.current[`${block.id}:9`] = el;
                    }}
                  />
                </label>
              </div>
            )}

            {referenceModifier?.startsWith('send-to-chat-block') && (
              <label
                className='flex gap-2 items-center'
                htmlFor='chatBlockNumber'
              >
                <p className='text-sm'>
                  Enter chat block number here (it will be sent to the first
                  chat block if left blank):
                </p>
                <AutocompleteTextarea
                  autosize={true}
                  block={block}
                  index={index}
                  onChange={handleChange}
                  textAreaIndex={10}
                  containerClassName='flex items-center'
                  className='bg-transparent rounded-xl text-sm border border-black-100 shadow-inner w-16'
                  name='chatBlockNumber'
                  value={chatBlockNumber}
                />
              </label>
            )}
          </div>

          <button
            onClick={onProcessingSaveClick}
            className={testButtonStyles}
          >
            TEST
          </button>
          <div
            className='overflow-x-auto'
            dangerouslySetInnerHTML={{ __html: resultHtml }}
          />
          <p>{responseDescription}</p>
        </div>
      )}
    </FuserLoader>
  );
};

export default ProcessingBlock;
