import React, {
  FC,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { FiPlus } from 'react-icons/fi';
import FuserLoader from '../../containers/FuserPage/FuserLoader';
import { MAX_PREVIEW_CHARS } from '../../constants/blocks';
import BlockProps from '../../models/BlockProps';
import MyToolTips from '../../components/MyTooltip';
import FuserContext from '../../context/FuserContext';
import useRunnerFocusOnEdit from '../../hooks/useRunnerFocusOnEdit';
import useBlockRunner from '../../hooks/useBlockRunner';
import AutocompleteTextarea from '../../containers/FuserPage/AutocompleteTextarea';
import { iconStyle, testButtonStyles } from '../../constants/styles';
import {
  deleteAtIndex,
  ensureNotArray,
  is2dArray,
  updateAtIndex,
  updateAtIndexRun,
} from '../../utils/array';
import {
  format_newlines,
  getOrdinalSuffix,
  truncateAfter,
} from '../../utils/string';
import { Spreadsheet, EmptySelection } from 'react-spreadsheet';

const blocksToPreserveOnSaveAnswer = ['question', 'info'];

const QuestionBlock: FC<BlockProps> = ({
  isLoading,
  index,
  block,
  setIsLoading,
  handleChange,
  collapsed,
}) => {
  const {
    blockStyles,
    blocks,
    setBlocks,
    setActivityLog,
    runnerMode,
    runnerIndex,
    replacePlaceholders,
    setCollapsedBlocks,
    restartQueued,
    blockScrollingIntoView,
    setBlockScrollingIntoView,
    // handleTextareaFocus,
    // textAreaRefs,
  } = useContext(FuserContext);

  useRunnerFocusOnEdit(block, index);

  useBlockRunner(() => {}, index);

  const updateBlocks = () => setBlocks(updateAtIndex(index, block, blocks));
  const [savedAnswer, setSavedAnswer] = useState<string>(
    "click 'update answer' to save the block"
  );
  const [tickVisible, setTickVisible] = useState(false);

  const menuButtonStyles =
    'bg-blue-200 dark:bg-neutral-900 dark:text-neutral-200 p-2 rounded-xl text-xs cursor-pointer hover:bg-blue-300 hover:shadow-lg transform hover:-translate-y-1 transition-all duration-200 ease-in-out w-10';

  useEffect(() => {
    if (block.data.multipleChoiceAnswers === undefined) {
      block.data.multipleChoiceAnswers = [];
    }
    if (block.data.selectValue === undefined) {
      block.data.selectValue = 'text';
    }
    if (block.data.checkedRadioValue === undefined) {
      block.data.checkedRadioValue = '';
    }
    if (block.data.response === undefined) {
      block.data.response = '';
    }
    updateBlocks();
  }, []);

  useEffect(() => {
    if (
      runnerMode &&
      selectValue === 'multiple-choice-radio' &&
      !checkedRadioValue
    ) {
      block.data.checkedRadioValue = block.data.multipleChoiceAnswers[0];
      updateBlocks();
    }
  }, [runnerMode]);

  // console.log(`response for block ${index}:`, block.data.response);

  useEffect(() => {
    const { selectValue, multipleChoiceAnswers, response } = block.data;
    if (
      selectValue === 'multiple-choice-radio' &&
      multipleChoiceAnswers.length > 0 &&
      response === ''
    ) {
      block.data.checkedRadioValue = multipleChoiceAnswers[0];
    }

    // if (typeof block?.data?.inputToProcess === 'string') {
    //   const processedInput = replacePlaceholders(
    //     block.data.inputToProcess,
    //     blocks
    //   );
    //   block.data.processedInput = processedInput;
    // }
    updateBlocks();
  }, [block]);

  const onQuestionSaveClick = async () => {
    // response is automatically saved for text and checkbox inputs
    setIsLoading(true); // start loading
    const {
      response,
      selectValue,
      checkedRadioValue,
      multipleChoiceAnswers,
      inputToProcess,
    } = block.data;

    // console.log(
    //   'questionblock response is ',
    //   response,
    //   'selectValue is',
    //   block.data.selectValue,
    //   'checkedradiovalue is',
    //   checkedRadioValue,
    //   block.data.multipleChoiceAnswers
    // );
    /*
    if (selectValue === 'text' && (
      response === undefined || 
      Array.isArray(response) && response.length === 0 || 
      typeof response === 'string' && response.trim() === ''
    )) {
      setErrorMessage('Please enter some text');
      setIsLoading(false);
      return;
    }

    setErrorMessage('');
    */

    if (
      selectValue.startsWith('multiple-choice') &&
      multipleChoiceAnswers.length === 0
    ) {
      setErrorMessage('Please add some answer options');
    } else {
      setErrorMessage('');
    }

    if (selectValue.endsWith('radio')) {
      block.data.response = checkedRadioValue === '' ? [] : [checkedRadioValue];
    }

    //console.log('question response', block.data.response);

    //const questionResponse = await gpt(processedQuestion);

    if (!runnerMode) {
      let resultHtml = '';
      resultHtml += format_newlines(response);
      setSavedAnswer(resultHtml.substring(0, 500));
    } else {
      setTickVisible(true);
      const collapseAfterRunning = [true, undefined].includes(
        block.collapseAfterRunning
      );
      setCollapsedBlocks((currentCollapsedBlocks: any) => [
        ...(collapseAfterRunning ? [block.id] : []),
        ...currentCollapsedBlocks,
      ]);
      setTimeout(() => setTickVisible(false), 1000);
    }
    //newBlocks[index].data.response = processedQuestion;

    //setInputParagraph(inputHtml);
    //setResultParagraph(resultHtml);
    //updateBlocks();

    setIsLoading(false);
    setActivityLog((prevLog: string[]) => [
      ...prevLog,
      `Saved question block at index: ${index}`,
    ]);
    //setWaitingForUserInput(false); // if in runner mode
    const indexOfNextBlockToRun = blocks.findIndex(
      (block: any, blockIndex: number) =>
        blockIndex > index &&
        (block.data.response?.length === 0 ||
          !blocksToPreserveOnSaveAnswer.includes(block.blocktype))
    );

    console.log('indexOfNextBlockToRun', indexOfNextBlockToRun);
    setBlocks(updateAtIndexRun(index, block, blocks));
  };

  const onSendAnswerClick = async () => {
    // clears any later responses
    if (runnerMode) {
      setBlocks((blocks: any) =>
        blocks.map((block: any, blockIndex: number) => {
          // if (blockIndex === index && index == runnerIndex) {
          //   console.log("setting updatedBlock true at index", blockIndex);
          //   return { ...block, updatedBlock: true };
          // }

          return blockIndex > index &&
            !blocksToPreserveOnSaveAnswer.includes(block.blocktype)
            ? { ...block, data: { ...block.data, response: [] } }
            : block;
        })
      );
    }
    await onQuestionSaveClick();
  };

  const handleSelectChange = (
    e: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    if (errorMessage === 'Please add some answer options') {
      setErrorMessage('');
    }
    if (e.target.value === 'paste-spreadsheet') {
      block.data.response = spreadsheetDataToResponse(
        spreadsheetDataRef.current
      );
    }
    block.data[e.target.name] = e.target.value;
    block.data.response = e.target.value.endsWith('checkboxes') ? [] : '';
    updateBlocks();
  };

  const [answerFormVisible, setAnswerFormVisible] = useState<boolean>(false);
  const [answerError, setAnswerError] = useState<string>('');

  const createMultipleChoiceAnswer = (value: string) => {
    value = value.trim();

    const { multipleChoiceAnswers, selectValue } = block.data;

    if (value === '') {
      setAnswerError('Please enter some text');
      return false;
    }

    const indexOfClash = multipleChoiceAnswers.findIndex(
      (answerInputValue: string) => value === answerInputValue
    );

    if (indexOfClash !== -1) {
      setAnswerError(
        `This is already the ${getOrdinalSuffix(indexOfClash + 1)} option.`
      );
      return false;
    } else {
      if (
        selectValue === 'multiple-choice-radio' &&
        multipleChoiceAnswers.length === 0
      ) {
        block.data.checkedRadioValue = value;
      }
      block.data.multipleChoiceAnswers = [...multipleChoiceAnswers, value];

      updateBlocks();
      setAnswerError('');

      return true;
    }
  };

  const updateMultipleChoiceAnswer = (
    indexToUpdate: number,
    newValue: string
  ) => {
    newValue = newValue.trim();

    const { multipleChoiceAnswers, response, checkedRadioValue } = block.data;

    const oldValue = multipleChoiceAnswers[indexToUpdate];

    if (newValue === '') {
      setAnswerError('Please enter some text');
      return false;
    }

    const indexOfClash = multipleChoiceAnswers.findIndex(
      (answerInputValue: string, inputIndex: number) =>
        indexToUpdate !== inputIndex && newValue === answerInputValue
    );

    if (indexOfClash !== -1) {
      setAnswerError(
        `This is already the ${getOrdinalSuffix(indexOfClash + 1)} option.`
      );
      return false;
    } else {
      block.data.multipleChoiceAnswers = updateAtIndex(
        indexToUpdate,
        newValue,
        multipleChoiceAnswers
      );

      if (selectValue.endsWith('checkboxes') && response.includes(oldValue)) {
        block.data.response = response.map((answer: string) =>
          answer === oldValue ? newValue : answer
        );
      }
      if (selectValue.endsWith('radio') && checkedRadioValue === oldValue) {
        block.data.checkedRadioValue = newValue;
      }

      updateBlocks();
      return true;
    }
  };

  const deleteMultipleChoiceAnswer = (indexToDelete: number) => {
    const { multipleChoiceAnswers, response, checkedRadioValue } = block.data;
    const answerToDelete = multipleChoiceAnswers[indexToDelete];
    const newMultipleChoiceAnswers = deleteAtIndex(
      indexToDelete,
      multipleChoiceAnswers
    );

    block.data.multipleChoiceAnswers = newMultipleChoiceAnswers;

    if (selectValue.endsWith('checkboxes')) {
      block.data.response = response.filter(
        (answer: string) => answer !== answerToDelete
      );
    }

    if (selectValue.endsWith('radio') && checkedRadioValue === answerToDelete) {
      block.data.checkedRadioValue =
        newMultipleChoiceAnswers.length > 0 ? newMultipleChoiceAnswers[0] : '';
    }

    updateBlocks();
  };

  const [answerFormInputValue, setAnswerFormInputValue] = useState<string>('');
  const [answerBeingEdited, setAnswerBeingEdited] = useState<number | false>(
    false
  );

  const showAnswerForm = () => setAnswerFormVisible(true);
  const hideAnswerForm = () => setAnswerFormVisible(false);

  const handleAddAnswer = () => {
    if (createMultipleChoiceAnswer(answerFormInputValue)) {
      if (errorMessage === 'Please add some answer options') {
        setErrorMessage('');
      }
      setAnswerFormInputValue('');
      hideAnswerForm();
    }
  };

  const handleUpdateAnswer = () => {
    if (answerBeingEdited === false) {
      console.log('Error: handleUpdateAnswer called with false');
    } else if (
      updateMultipleChoiceAnswer(answerBeingEdited, answerFormInputValue)
    ) {
      setAnswerError('');
      setAnswerBeingEdited(false);
      setAnswerFormInputValue('');
      hideAnswerForm();
    }
  };

  const handleDiscardChanges = () => {
    setAnswerBeingEdited(false);
    setAnswerFormInputValue('');
    hideAnswerForm();
  };

  const handleAnswerInputFormChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAnswerFormInputValue(e.target.value);
  };

  const handleEditClick = (index: number) => () => {
    setAnswerBeingEdited(index);
    setAnswerFormInputValue(block.data.multipleChoiceAnswers[index]);
    showAnswerForm();
  };

  const handleDeleteClick = (index: number) => () => {
    deleteMultipleChoiceAnswer(index);
    if (block.data.response === block.data.multipleChoiceAnswers[index]) {
      block.data.response = undefined;
    }
    setAnswerBeingEdited(false);
    setAnswerFormInputValue('');
    hideAnswerForm();
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    const { response } = block.data;

    if (checked) {
      block.data.response = [...response, value];
    } else {
      block.data.response = response.filter(
        (answer: string) => answer !== value
      );
    }
    updateBlocks();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();

      // this is triggered when readAsText completes
      reader.onload = e => {
        const content = e?.target?.result;
        block.data.response = content;
        updateBlocks();
      };

      reader.readAsText(file);
    }
    handleChange(event);
  };

  // const onTextareaFocus =
  //   (textareaId: number) =>
  //   (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
  //     handleTextareaFocus(e, block.id, textareaId);
  //   };

  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    inputToProcess,
    response,
    selectValue,
    multipleChoiceAnswers,
    checkedRadioValue,
    // uploadedFileName,
  } = block.data;
  const radioGroupName = `radio-group-${index}`;

  const isNonEmptyArray = (x: any) => Array.isArray(x) && x.length > 0;

  // console.log(
  //   !Array.isArray(response) && response,
  //   runnerIndex >= index ||
  //     isNonEmptyArray(response) ||
  //     (!Array.isArray(response) && response)
  //     ? 'YES'
  //     : 'NO'
  // );

  const showResponse =
    runnerIndex >= index ||
    isNonEmptyArray(response) ||
    (!Array.isArray(response) && response);

  let question = inputToProcess;

  if (Array.isArray(question)) {
    question = question[0];
  }

  try {
    question = replacePlaceholders(inputToProcess, blocks);

    if (Array.isArray(question)) {
      question = question[0];
    }
    question = question.replace(/\n/g, '<br>');
  } catch (e) {
    //console.log("missing  block",blocks[1].data);
    question = inputToProcess;
  }

  const saveAnswerIfEnterPressed = (e: any) => {
    if (e.key === 'Enter') {
      onSendAnswerClick();
      e.target.blur();
    }
  };

  const textInputRef = useRef<any>();
  const textareaInputRef = useRef<any>();

  const focusElementIfReady = (element: any) => {
    if (
      runnerMode &&
      runnerIndex === index &&
      element &&
      !isLoading &&
      !restartQueued
    ) {
      element.focus();
    }
  };

  const textInputCallbackRef = useCallback(
    (element: any) => {
      if (!element) {
        return;
      }
      textInputRef.current = element;
      focusElementIfReady(element);
    },
    [runnerMode, runnerIndex, index, isLoading, restartQueued]
  );
  const textareaInputCallbackRef = useCallback(
    (element: any) => {
      if (!element) {
        return;
      }
      textareaInputRef.current = element;
      focusElementIfReady(element);
    },
    [runnerMode, runnerIndex, index, isLoading, restartQueued]
  );

  useEffect(() => {
    if (selectValue === 'text' && textInputRef.current) {
      focusElementIfReady(textInputRef.current);
    }
    if (selectValue === 'textarea' && textareaInputRef.current) {
      focusElementIfReady(textareaInputRef.current);
    }
  }, [runnerMode, runnerIndex, index, isLoading, selectValue, restartQueued]);

  useEffect(() => {
    if (blockScrollingIntoView && runnerMode && runnerIndex === index) {
      if (selectValue === 'text' && textInputRef.current) {
        setTimeout(() => textInputRef.current.focus(), 500);
      }
      if (selectValue === 'textarea' && textareaInputRef.current) {
        setTimeout(() => textareaInputRef.current.focus(), 500);
      }
    }
    setBlockScrollingIntoView(false);
  }, [blockScrollingIntoView]);

  const spreadsheetDataToResponse = (spreadsheetData: any) => {
    return spreadsheetData.map((row: any) =>
      row.map((cell: any) => cell?.value)
    );
  };

  const defaultSpreadsheetResponse = [['']];

  const defaultSpreadsheetData = [[{ value: '' }]];

  const initialSpreadsheetData = (
    is2dArray(response) ? response : defaultSpreadsheetResponse
  ).map((row: string[]) =>
    row.map((cellValue: string) => ({ value: cellValue }))
  );

  const spreadsheetDataRef = useRef<any>(initialSpreadsheetData);

  useEffect(() => {
    spreadsheetDataRef.current = initialSpreadsheetData;
  }, [runnerMode]);

  // const memoizedResponse: any = useDeepCompareMemo(response);

  // useEffect(() => {
  //   if (memoizedResponse && is2dArray(memoizedResponse)) {
  //     setSpreadsheetData(
  //       memoizedResponse.map((row: string[]) =>
  //         row.map((cellValue: string) => ({ value: cellValue }))
  //       )
  //     );
  //   } else {
  //     setSpreadsheetData([[{ value: "Hi" }]]);
  //   }
  // }, [memoizedResponse]);

  const [selectedSpreadsheetRange, setSelectedSpreadsheetRange] =
    useState<any>();

  const defaultAddRowsOrColumnsInputValue = 1;
  const defaultAddRowsOrColumnsSelectValue = 'rows';

  const addRowsOrColumnsInputRef = useRef<any>(
    defaultAddRowsOrColumnsInputValue
  );
  const addRowsOrColumnsSelectRef = useRef<any>(
    defaultAddRowsOrColumnsSelectValue
  );

  const contentToRender = (
    <>
      {runnerMode ? (
        showResponse && question ? (
          // older tools may not have a processedInput
          <h1
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          ></h1>
        ) : (
          <div>Question block</div>
        )
      ) : (
        <>
          <label className='text-xs'>
            Question for user:{' '}
            <MyToolTips
              content="
<p>Use questions to gather information from the user.</p>

<p>A good tool should make use of user input, so it doesn't give everyone the same answer.</p>

<p>You could use the user's input: <br />
- in prompts <br />
- with embedding blocks to search information you have added <br />
- with 'if.. else...' blocks to control the flow of the tool
</p>
"
              tipID='block-types'
              datatooltipplace='below'
            />
          </label>

          <AutocompleteTextarea
            block={block}
            index={index}
            onChange={handleChange}
            textAreaIndex={0}
            className='w-full resize-none bg-transparent rounded-xl text-sm border border-neutral-100 shadow-inner h-36 overflow-y-scroll'
            name='inputToProcess'
            value={inputToProcess || ''}
          />

          <br />
          <br />

          <label
            className='text-xs'
            htmlFor='input-type-select'
          >
            Input Type:
          </label>

          <select
            className='resize-none bg-transparent rounded-xl text-xs border border-neutral-100 shadow-inner'
            name='selectValue'
            value={selectValue}
            onChange={handleSelectChange}
          >
            <option value='text'>Text</option>
            <option value='text-area'>Text area</option>
            <option value='text-file-upload'>Text File Upload</option>
            <option value='multiple-choice-radio'>
              Multiple choice (one answer)
            </option>
            <option value='multiple-choice-checkboxes'>
              Multiple choice (multiple answers)
            </option>
            <option value='paste-spreadsheet'>Paste in Spreadsheet</option>
            {/*<option value="upload-spreadsheet">Upload Spreadsheet</option>*/}
          </select>

          <br />
          <br />
        </>
      )}

      {(!runnerMode || (runnerMode && showResponse)) && (
        <>
          {selectValue === 'text' && (
            // <input type="text" placeholder="Your text here" name="selectValueText" value={block.data.selectValueText || ''} onChange={(e) => handleChange(index, e)}/>
            <input
              type='text'
              placeholder='Your text here'
              name='response'
              value={response || ''}
              onChange={handleChange}
              onKeyDown={saveAnswerIfEnterPressed}
              ref={textInputCallbackRef}
            />
          )}

          {selectValue === 'text-area' && (
            // <input type="text" placeholder="Your text here" name="selectValueText" value={block.data.selectValueText || ''} onChange={(e) => handleChange(index, e)}/>
            <textarea
              placeholder='Your text here'
              name='response'
              value={response || ''}
              onChange={handleChange}
              ref={textareaInputCallbackRef}
            />
          )}

          {selectValue === 'text-file-upload' && (
            <input
              type='file'
              accept='.txt'
              name='uploadedFileName'
              onChange={handleFileChange}
            />
            // was block.data.inputTypeSelectTextFile
          )}

          {selectValue?.startsWith('multiple-choice') && (
            <>
              {
                <fieldset className='bg-transparent'>
                  {runnerMode || (
                    <legend className='bg-transparent text-xs'>Answers:</legend>
                  )}
                  {multipleChoiceAnswers.map(
                    (answer: string, choiceIndex: number) => {
                      return (
                        <div
                          key={choiceIndex}
                          className='bg-transparent w-full flex items-center justify-between'
                        >
                          <div className='bg-transparent flex gap-3 items-center justify-between p-2'>
                            {selectValue.endsWith('radio') ? (
                              <input
                                type='radio'
                                id={`multiple-choice-answer-${choiceIndex}`}
                                name={radioGroupName}
                                value={answer}
                                onChange={handleChange}
                                checked={answer === checkedRadioValue}
                              />
                            ) : (
                              <input
                                type='checkbox'
                                id={`multiple-choice-answer-${choiceIndex}`}
                                name='response'
                                value={answer}
                                checked={response.includes(answer)}
                                onChange={handleCheckboxChange}
                              />
                            )}

                            <label htmlFor='response'>{answer}</label>
                            {runnerMode || (
                              <>
                                <button onClick={handleEditClick(choiceIndex)}>
                                  Edit
                                </button>
                                <button
                                  onClick={handleDeleteClick(choiceIndex)}
                                >
                                  Delete
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </fieldset>
              }
              {runnerMode ||
                (answerFormVisible ? (
                  answerBeingEdited !== false ? (
                    <>
                      <div className='flex items-center justify-between'>
                        <div className='flex gap-3 items-center justify-between p-2'>
                          <input
                            type='text'
                            value={answerFormInputValue}
                            onChange={handleAnswerInputFormChange}
                          />
                          <button onClick={handleUpdateAnswer}>Update</button>
                          <button onClick={handleDiscardChanges}>
                            Discard changes
                          </button>
                        </div>
                      </div>
                      {answerError === '' || <p>{answerError}</p>}
                    </>
                  ) : (
                    <>
                      <div className='flex items-center justify-between'>
                        <div className='flex gap-3 items-center justify-between p-2'>
                          <input
                            type='text'
                            placeholder='Enter the next answer option here'
                            value={answerFormInputValue}
                            onChange={handleAnswerInputFormChange}
                          />
                          <button onClick={handleAddAnswer}>Add</button>
                          <button onClick={handleDiscardChanges}>
                            Discard
                          </button>
                        </div>
                      </div>
                      {answerError === '' || <p>{answerError}</p>}
                    </>
                  )
                ) : (
                  <button
                    className={`${menuButtonStyles}`}
                    onClick={showAnswerForm}
                  >
                    <FiPlus className={`${iconStyle}`} />
                  </button>
                ))}
            </>
          )}

          {selectValue === 'paste-spreadsheet' && (
            <>
              {!runnerMode && (
                <label className='flex gap-2 items-center'>
                  <input
                    type='checkbox'
                    onChange={() => {
                      block.data.useColumnHeadingReferences =
                        !block.data.useColumnHeadingReferences;
                      console.log(block.data.useColumnHeadingReferences);
                      updateBlocks();
                    }}
                    checked={block.data.useColumnHeadingReferences}
                  />
                  Allow column headings for referencing
                  <MyToolTips
                    tipID={`spreadsheet-help-${index}`}
                    content={`
                      <p>You can reference ranges of the spreadsheet by writing @Blockname[range]</p>
                      <p>Valid syntax for ranges includes:</p>
                      <ul>
                        <li>-- Numbers (to reference rows)</li>
                        <li>-- Letters/column headings (to reference columns)</li>
                        <li>-- Letters/column headings followed by numbers (to reference cells), e.g. A2 or Heading3</li>
                        <li>-- Any two of the above joined with ":" (to reference cells from the first to the second reference), e.g. 3:9 or Heading:F</li>
                      </ul>
                    `}
                    datatooltipplace='right'
                  />
                </label>
              )}
              <div className='max-h-96 w-full overflow-x-scroll overflow-y-scroll'>
                <Spreadsheet
                  data={spreadsheetDataRef.current}
                  onChange={spreadsheet => {
                    block.data.response =
                      spreadsheetDataToResponse(spreadsheet);
                    spreadsheetDataRef.current = spreadsheet;
                    updateBlocks();
                  }}
                  onSelect={range => setSelectedSpreadsheetRange(range)}
                  selected={selectedSpreadsheetRange}
                />
              </div>
              <div className='flex gap-8 items-center'>
                <div className='flex items-center gap-2'>
                  <button
                    className='bg-blue-100 p-2 rounded-lg hover:bg-white w-fit border border-1 border-black'
                    onClick={() => {
                      const spreadsheetData = spreadsheetDataRef.current;
                      if (addRowsOrColumnsSelectRef.current.value === 'rows') {
                        const numberOfRows =
                          +addRowsOrColumnsInputRef.current.value;
                        for (let i = 0; i < numberOfRows; i++) {
                          const blankRow = spreadsheetData[0].map(
                            (cell: any) => ({
                              value: '',
                            })
                          );
                          spreadsheetData.push(blankRow);
                        }
                      } else {
                        const numberOfColumns =
                          +addRowsOrColumnsInputRef.current.value;
                        for (const row of spreadsheetData) {
                          for (let i = 0; i < numberOfColumns; i++) {
                            row.push({ value: '' });
                          }
                        }
                      }

                      block.data.response =
                        spreadsheetDataToResponse(spreadsheetData);
                      spreadsheetDataRef.current = [...spreadsheetData]; // set to a copy of spreadsheetData to force a rerender
                      updateBlocks();
                    }}
                  >
                    Add
                  </button>
                  <input
                    className='h-10 w-20 p-2 border border-1 border-black'
                    defaultValue={defaultAddRowsOrColumnsInputValue}
                    ref={addRowsOrColumnsInputRef}
                  />
                  <select
                    defaultValue={defaultAddRowsOrColumnsSelectValue}
                    ref={addRowsOrColumnsSelectRef}
                  >
                    <option value='rows'>Rows</option>
                    <option value='columns'>Columns</option>
                  </select>
                </div>
                <button
                  className='bg-blue-100 p-2 rounded-lg hover:bg-white w-fit border border-1 border-black'
                  onClick={() => {
                    setSelectedSpreadsheetRange(EmptySelection);
                    block.data.response = defaultSpreadsheetResponse;
                    spreadsheetDataRef.current = defaultSpreadsheetData;
                    updateBlocks();
                  }}
                >
                  Clear spreadsheet
                </button>
              </div>
            </>
          )}

          {selectValue === 'upload-spreadsheet' && (
            <input
              type='file'
              accept='.csv,.xlsx,.xls'
              name='response'
              //value={response || ''}
              onChange={handleChange}
            />
            // was block.data.inputTypeSelectUploadedSpreadsheet
          )}

          <p>{errorMessage}</p>

          <div className='flex items-center gap-2'>
            {!runnerMode || runnerIndex >= index || response?.length > 0 ? (
              <button
                onClick={onSendAnswerClick}
                className={testButtonStyles}
              >
                save answer
              </button>
            ) : (
              ''
            )}
            <span>{tickVisible ? ' ✔' : ''}</span>
          </div>

          {!runnerMode && (
            <>
              <br />
              <br />
              <label htmlFor='question-message-input'>Test answer:</label>
              <div
                className='overflow-x-auto w-full'
                dangerouslySetInnerHTML={{
                  __html: selectValue?.endsWith('checkboxes')
                    ? response
                    : typeof ensureNotArray(response) === 'string'
                      ? `${truncateAfter(1000, ensureNotArray(response))}`
                      : '',
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );

  if (collapsed) {
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: truncateAfter(MAX_PREVIEW_CHARS, `Question: ${question}`),
          }}
        />
        {response?.length > 0 && (
          <p>
            {truncateAfter(MAX_PREVIEW_CHARS, `Answer: ${response.toString()}`)}{' '}
          </p>
        )}
      </>
    );
  }
  return (
    <FuserLoader
      name='Question Block'
      loading={isLoading}
    >
      {runnerMode ? (
        contentToRender
      ) : (
        <div
          className={blockStyles}
          key={index}
        >
          {contentToRender}
        </div>
      )}
    </FuserLoader>
  );
};

// Could use a wrapper like below instead of using contentToRender,
// it may render more predictably this way
// To use this, rename QuestionBlock above to QuestionBlockContent: FC<any>
// const QuestionBlock: FC<BlockProps> = ({
//   isLoading,
//   index,
//   block,
//   setIsLoading,
//   handleChange,
//   collapsed,
// }) => {
//   const { runnerMode, blockStyles } = useContext(FuserContext);
//   return (
//     <FuserLoader
//       name='Question Block'
//       loading={isLoading}
//     >
//       {runnerMode ? (
//         <QuestionBlockContent
//           index={index}
//           block={block}
//           setIsLoading={setIsLoading}
//           handleChange={handleChange}
//           collapsed={collapsed}
//         />
//       ) : (
//         <div
//           className={blockStyles}
//           key={index}
//         >
//           <QuestionBlockContent
//             index={index}
//             block={block}
//             setIsLoading={setIsLoading}
//             handleChange={handleChange}
//             collapsed={collapsed}
//           />
//         </div>
//       )}
//     </FuserLoader>
//   );
// };

export default QuestionBlock;
